import { FormattedMessage } from 'react-intl';

import { AccountType } from '@/types';
import { company, personal, unknown } from '@intl/generic';

type Props = {
  type: AccountType;
};

const accountOptions: { [key in AccountType]: JSX.Element } = {
  unknown: <FormattedMessage {...unknown} />,
  company: <FormattedMessage {...company} />,
  personal: <FormattedMessage {...personal} />,
} as const;

export default function AccountTypeText({ type }: Props) {
  return <span>{accountOptions[type]}</span>;
}
