import { defineMessage, useIntl } from 'react-intl';

import Browse from '@components/browse';
import PageTitle from '@components/ui/PageTitle';
import SEO from '@components/ui/SEO';

const message = defineMessage({
  id: 'browse.header',
  description: 'Page header for browse page',
  defaultMessage: 'Browse',
});

const BrowsePage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage(message);

  return (
    <section className="container max-w-4xl">
      <PageTitle text={title} />
      <SEO pageTitle={title} />
      <Browse />
    </section>
  );
};

export default BrowsePage;
