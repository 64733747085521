import axios, { AxiosRequestConfig } from 'axios';

import config from '@/config';
import { ExtendExpirationCommandProps, InitializeAccountCommandProps, StripeProduct } from '@/types';
import { devLog } from '@utils/helpers';

const getAxios = () => {
  return axios;
};

export const sendInitializeAccountCommand = (
  data: Omit<InitializeAccountCommandProps, 'timestamp'> & { passphrase: string },
) => {
  const url = `${config.backend}/blockchain/initialize`;

  return getAxios().post(url, data);
};

export const sendExtendExpirationCommand = (
  data: Omit<ExtendExpirationCommandProps, 'timestamp'> & { passphrase: string },
) => {
  const url = `${config.backend}/blockchain/extend-expiration`;

  return getAxios().post<{ asset: ExtendExpirationCommandProps }>(url, data);
};

/** helpers */
export const handleLoadingProgress = (
  e: ProgressEvent,
  setLoadingProgress: (value: React.SetStateAction<number>) => void,
) => {
  const progress = (e.loaded / e.total) * 100;
  devLog(`Loaded: ${e.loaded}, Total: ${e.total}, Progress: ${progress}`);
  setLoadingProgress(Math.round(progress));
};

export const fetchStripeProducts = async (): Promise<StripeProduct[]> => {
  const url = `${config.backend}/stripe/products`;

  return getAxios()
    .get(url)
    .then(res => res.data);
};

export const handleDamDownload = (data: { passphrase: string; fileId: string }, options?: AxiosRequestConfig) => {
  const url = `${config.backend}/dam/download`;

  const baseOptions: AxiosRequestConfig = {
    responseType: 'arraybuffer',
  };

  const requestOptions = options ? { ...baseOptions, ...options } : baseOptions;

  return getAxios().post(url, data, requestOptions);
};

export const handleDamUpload = (data: FormData, options?: AxiosRequestConfig) => {
  const url = `${config.backend}/dam/upload`;

  return getAxios().post(url, data, options);
};

export const handleDamFileTransfer = (data: {
  accept: boolean;
  passphrase: string;
  fileId: string;
  requestId: string;
}) => {
  const url = `${config.backend}/dam/handle-file-request`;

  return getAxios().post(url, data);
};

export const handleDamCollectionTransfer = (data: {
  accept: boolean;
  passphrase: string;
  collectionId: string;
  requestId: string;
}) => {
  const url = `${config.backend}/dam/handle-collection-request`;

  return getAxios().post(url, data);
};
