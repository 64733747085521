import { AxiosError } from 'axios';
import { toast } from 'react-hot-toast';
import { Buffer } from 'buffer';

import { devLog } from './helpers';

type ApiResponse = {
  status: number;
  message: string;
};

export const handleError = (error: unknown) => {
  let message = 'Something went wrong';

  devLog(error);

  if (!error) {
    // keep standard message
  } else if (typeof error === 'string') {
    message = error;
  } else if (
    typeof error === 'object' &&
    (error as Partial<ApiResponse>)?.status &&
    (error as Partial<ApiResponse>)?.message
  ) {
    message = (error as Partial<ApiResponse>).message!;
  } else if (error instanceof AxiosError) {
    try {
      const parsedData = JSON.parse(Buffer.from(error?.response?.data).toString('utf-8'));

      if (typeof parsedData === 'object' && parsedData?.message) {
        message = parsedData.message;
      } else {
        message = error.response?.data?.message || error.message;
      }
    } catch (err) {
      message = error.message;
    }
  } else if (error instanceof Error) {
    if (error.message === 'Incoming transaction fee is not sufficient to replace existing transaction') {
      message = 'Previous action is still processing';
    } else {
      message = error.message;
    }
  } else {
    message = JSON.stringify(error);
  }

  toast.error(typeof message === 'string' ? message : JSON.stringify(message));
};
