import { Auth0Provider } from '@auth0/auth0-react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import config from '@/config';
import { useIntl } from 'react-intl';

type Props = {
  children: React.ReactNode | React.ReactNode[];
};

const Authentication = ({ children }: Props) => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const handleLogin = () => {
    toast.success(
      formatMessage({
        id: 'login.success',
        defaultMessage: 'Successfully logged in!',
      }),
    );

    navigate('/landing');
  };

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0ClientId}
      redirectUri={window.location.origin}
      onRedirectCallback={handleLogin}
      useRefreshTokens
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default Authentication;
