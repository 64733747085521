import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@components/ui/Button';
import Empty from '@components/ui/Empty';
import Icon from '@components/ui/Icon';
import { actions, label } from '@intl/generic';
import { Network } from '@/types';

type TableProps = { data: Network[]; handleSelectNetworkUpdate: (networkId: string) => void };

const NetworkTable = ({ data, handleSelectNetworkUpdate }: TableProps) => {
  const { formatMessage } = useIntl();

  return (
    <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
      <table className="w-full text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50 whitespace-nowrap">
          <tr>
            <th scope="col" className="px-6 py-3 w-full">
              <FormattedMessage {...label} />
            </th>

            <th scope="col" className="px-6 py-3">
              <FormattedMessage {...actions} />
            </th>
          </tr>
        </thead>
        <tbody className="bg-white">
          {data.length === 0 && (
            <tr>
              <td colSpan={2}>
                <Empty
                  text={formatMessage({ id: 'networks.table.noData', defaultMessage: 'You do not own any networks' })}
                />
              </td>
            </tr>
          )}

          {data.map(network => (
            <tr className="bg-white">
              <td className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap">{network.label}</td>

              <td className="px-6 py-4">
                <div className="flex gap-3 justify-center">
                  <Button link onClick={() => handleSelectNetworkUpdate(network.id)}>
                    <Icon type="faEdit" />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default NetworkTable;
