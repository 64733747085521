import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { MapStoreData } from '@/types';
import useWallet from '@hooks/useWallet';
import { notFound, usernameAlreadyExists, validUsername } from '@intl/generic';
import { fetchMapByEmailHashOrUsername } from '@services/api';
import { getClasses } from '@utils/helpers';

const DEBOUNCE = 500;

type Props = {
  usernameInput: string;
  setUsernameInput: React.Dispatch<React.SetStateAction<string>>;
  setUsernameAccountMap: React.Dispatch<React.SetStateAction<MapStoreData | null>>;
  existingAccounts?: string[];
  placeholder?: string;
  disabled?: boolean;
};

export default function UsernameInput({
  usernameInput,
  setUsernameInput,
  setUsernameAccountMap,
  existingAccounts,
  placeholder,
  disabled,
}: Props) {
  const { wallet } = useWallet();

  const [usernameInputFeedback, setUsernameInputFeedback] = useState<
    'success' | 'failed' | 'alreadyExists' | 'ownAddress' | null
  >(null);

  useEffect(() => {
    setUsernameInputFeedback(null);

    const id = setTimeout(async () => {
      const map = await fetchMapByEmailHashOrUsername({ username: usernameInput });

      if (usernameInput === '') {
        setUsernameInputFeedback(null);
      } else if (map && map.lsk32address === wallet?.lsk32address) {
        setUsernameInputFeedback('ownAddress');
      } else if (map && existingAccounts?.includes(map.lsk32address)) {
        setUsernameInputFeedback('alreadyExists');
      } else {
        setUsernameInputFeedback(map ? 'success' : 'failed');
      }

      setUsernameAccountMap(map);
    }, DEBOUNCE);

    return () => {
      clearTimeout(id);
    };
  }, [usernameInput, existingAccounts, wallet?.lsk32address, setUsernameAccountMap]);

  return (
    <div className="w-full h-12">
      <input
        placeholder={placeholder}
        value={usernameInput}
        onChange={e => setUsernameInput(e.target.value)}
        type="text"
        className={getClasses('base-input')}
        disabled={disabled}
      />
      <p className="text-xs mt-1">
        {usernameInputFeedback === 'success' && (
          <span className="text-green-400">
            <FormattedMessage {...validUsername} />
          </span>
        )}
        {usernameInputFeedback === 'failed' && (
          <span className="text-red-400">
            <FormattedMessage {...notFound} />
          </span>
        )}
        {usernameInputFeedback === 'alreadyExists' && (
          <span className="text-red-400">
            <FormattedMessage {...usernameAlreadyExists} />
          </span>
        )}
        {usernameInputFeedback === 'ownAddress' && (
          <span className="text-red-400">
            <FormattedMessage id="generic.form.ownAddressError" defaultMessage="Can not use own address" />
          </span>
        )}
      </p>
    </div>
  );
}
