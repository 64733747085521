import { ReactNode } from 'react';

import { getClasses } from '@utils/helpers';

type Props = {
  onClick: () => void;
  children: ReactNode | ReactNode[];
  isSelected?: boolean;
};

export default function SmallDivButton({ onClick, children, isSelected }: Props) {
  return (
    <div
      className={getClasses(
        'cursor-pointer transition-colors',
        isSelected ? 'text-secondary-400 hover:text-secondary-300' : 'text-gray-300 hover:text-secondary-300',
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}
