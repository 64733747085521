import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useIntl } from 'react-intl';

import Empty from '@components/ui/Empty';
import Error from '@components/ui/Error';
import PageTitle from '@components/ui/PageTitle';
import SEO from '@components/ui/SEO';
import useAccountData from '@hooks/useAccountData';
import { useCollectionData } from '@hooks/useCollectionData';
import useWallet from '@hooks/useWallet';
import { getFileById } from '@services/api';
import { handleError } from '@utils/errors';
import { devLog, fileIsTimedTransfer, getCurrentTimestamp, isSameAddress } from '@utils/helpers';
import NotFoundPage from 'pages/NotFoundPage';

import FileCard from './FileCard';
import FileHistory from './FileHistory';
import FileRequests from './FileRequests';

type Props = {
  id: string;
};

const View = ({ id }: Props) => {
  const { wallet } = useWallet();
  const { account } = useAccountData();
  const { isAuthenticated } = useAuth0();
  const { formatMessage } = useIntl();

  const { isError, data } = useQuery({
    queryKey: ['view', id],
    queryFn: () => getFileById(id),
    onSuccess: data => devLog(data),
    onError: handleError,
  });

  const { collections } = useCollectionData(
    data?.meta.collection.id ? [data.meta.collection.id] : [],
    undefined,
    undefined,
    undefined,
    undefined,
    true,
  );

  const file = data;

  if (!file) {
    return <NotFoundPage />;
  }

  if (isError) {
    return <Error />;
  }

  const isExpired = file.meta.expiration.unix - getCurrentTimestamp() < 0;

  const isOwner = !!(isAuthenticated && file && isSameAddress(file.data.owner, wallet?.lsk32address));

  const freelyAccessible =
    collections[0]?.downloadPermissionLevel === 'public' || file.data.downloadPermissionLevel === 'public';

  const isAllowed =
    freelyAccessible ||
    !!account?.filesAllowed.includes(id) ||
    !!account?.collectionsAllowed.includes(file.meta.collection.id);

  return (
    <div>
      <SEO
        pageTitle={
          file?.data.title ??
          formatMessage({
            id: 'view.titleFallback',
            defaultMessage: 'View',
            description: 'Fallback title for view page',
          })
        }
      />
      <PageTitle
        text={formatMessage({
          id: 'view.title',
          defaultMessage: 'File Information',
          description: 'Title for view page',
        })}
      />

      {!file && <Empty />}
      {file && (
        <FileCard
          file={file}
          isOwner={isOwner}
          isAllowed={isAllowed}
          freelyAccessible={freelyAccessible}
          isExpired={isExpired}
        />
      )}
      {file && !isOwner && isAuthenticated && !fileIsTimedTransfer(file) && !file.meta.collection.title && (
        <FileRequests file={file} isAllowed={isAllowed} />
      )}
      {file && (
        <FileHistory history={file.data.history.sort((a, b) => (b.createdAt.unix > a.createdAt.unix ? 1 : -1))} />
      )}
    </div>
  );
};

export default View;
