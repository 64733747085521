import { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';

import { useCustomizationStore } from '@stores/useCustomizationStore';

// import de from '@assets/languages/de.json';
// import fr from '@assets/languages/fr.json';
import it from '@assets/languages/it.json';

export type Locale = 'en' | 'it';

type Props = {
  children: ReactNode;
};

const messages: { [key in Locale]: Record<string, string> | undefined } = {
  it,
  en: undefined,
};

export default function Intl({ children }: Props) {
  const { locale } = useCustomizationStore();

  return (
    <IntlProvider messages={messages[locale]} locale={locale} defaultLocale="en">
      {children}
    </IntlProvider>
  );
}
