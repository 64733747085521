import { useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import { DamFile, RequestFileAccessCommandProps, RequestFileOwnershipCommandProps } from '@/types';
import Button from '@components/ui/Button';
import Modal from '@components/ui/Modal';
import useWallet from '@hooks/useWallet';
import {
  accessPermissionRequested,
  cancel,
  noPassphraseError,
  ownershipRequested,
  request,
  requestAccessPermission,
  requestOwnership,
} from '@intl/generic';
import { sendRequestFileAccessCommand, sendRequestFileOwnershipCommand } from '@services/transactions';
import { handleError } from '@utils/errors';
import { getTransactionTimestamp } from '@utils/helpers';

type Props = {
  file: DamFile;
  isAllowed: boolean;
};

const FileRequests = ({ file, isAllowed }: Props) => {
  const [ownershipRequestIsOpen, setOwnershipRequestModalIsOpen] = useState(false);
  const [permissionRequestModalIsOpen, setPermissionRequestModalIsOpen] = useState(false);

  const { formatMessage } = useIntl();
  const { wallet } = useWallet();

  const handleOwnershipRequest = async () => {
    if (!wallet?.passphrase) {
      toast.error(formatMessage(noPassphraseError));
      return;
    }

    const params: RequestFileOwnershipCommandProps = {
      id: file.data.id,
      timestamp: getTransactionTimestamp(),
    };

    try {
      await sendRequestFileOwnershipCommand(wallet.passphrase, params);
      toast.success(formatMessage(ownershipRequested));
    } catch (err) {
      handleError(err);
    }

    setOwnershipRequestModalIsOpen(false);
  };

  const handleAccessPermissionRequest = async () => {
    if (!wallet?.passphrase) {
      toast.error(formatMessage(noPassphraseError));
      return;
    }

    const params: RequestFileAccessCommandProps = {
      id: file.data.id,
      timestamp: getTransactionTimestamp(),
    };

    try {
      await sendRequestFileAccessCommand(wallet.passphrase, params);
      toast.success(formatMessage(accessPermissionRequested));
    } catch (err) {
      handleError(err);
    }

    setPermissionRequestModalIsOpen(false);
  };

  const renderButtons = () => (
    <div className="text-center flex justify-center my-8">
      <div className="flex gap-4">
        <Button size="sm" color="primary-bordered" onClick={() => setOwnershipRequestModalIsOpen(true)}>
          <FormattedMessage {...requestOwnership} />
        </Button>

        {!isAllowed && (
          <Button size="sm" color="primary-bordered" onClick={() => setPermissionRequestModalIsOpen(true)}>
            <FormattedMessage {...requestAccessPermission} />
          </Button>
        )}
      </div>
    </div>
  );

  return (
    <>
      {renderButtons()}

      <Modal
        title={formatMessage({
          id: 'view.requestModal.requestOwnershipOfFile',
          defaultMessage: 'Request ownership of file',
          description: 'Modal title',
        })}
        isOpen={ownershipRequestIsOpen}
        handleClose={() => setOwnershipRequestModalIsOpen(false)}
      >
        <div className="text-center mb-2">
          <p className="mb-8">
            <FormattedMessage
              id="view.requestModal.requestOwnershipConfirmation"
              defaultMessage="You are about to request the ownership of {title}. Continue?"
              values={{ title: <span className="font-bold">{file.data.title}</span> }}
              description="Confirmation text"
            />
          </p>
        </div>

        <div className="flex justify-center gap-8">
          <Button type="button" color="primary-bordered" onClick={() => setOwnershipRequestModalIsOpen(false)}>
            <FormattedMessage {...cancel} />
          </Button>

          <Button type="button" onClick={handleOwnershipRequest}>
            <FormattedMessage {...request} />
          </Button>
        </div>
      </Modal>

      <Modal
        title={formatMessage({
          id: 'view.requestModal.requestDownloadPermissionOfFile',
          defaultMessage: 'Request download permission of file',
          description: 'Modal title',
        })}
        isOpen={permissionRequestModalIsOpen}
        handleClose={() => setPermissionRequestModalIsOpen(false)}
      >
        <div className="mb-8 text-center">
          <FormattedMessage
            id="view.requestModal.requestDownloadPermissionConfirmation"
            defaultMessage="You are about to request download permission of {title}. Continue?"
            values={{ title: <span className="font-bold">{file.data.title}</span> }}
            description="Confirmation text"
          />
        </div>

        <div className="flex justify-center gap-8">
          <Button type="button" color="primary-bordered" onClick={() => setPermissionRequestModalIsOpen(false)}>
            <FormattedMessage {...cancel} />
          </Button>

          <Button type="button" onClick={handleAccessPermissionRequest}>
            <FormattedMessage {...request} />
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default FileRequests;
