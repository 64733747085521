import { useQueryClient } from '@tanstack/react-query';
import { SyntheticEvent, useState } from 'react';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import { CreateNetworkCommandProps, MapStoreData, Network, UpdateNetworkCommandProps } from '@/types';
import Button from '@components/ui/Button';
import Icon from '@components/ui/Icon';
import Label from '@components/ui/Label';
import LabelDescription from '@components/ui/LabelDescription';
import SmallDivButton from '@components/ui/SmallDivButton';
import Toggle from '@components/ui/Toggle';
import useForm from '@hooks/useForm';
import useWallet from '@hooks/useWallet';
import { cancel, enterUsername, label as labelText, noWallet, submit, update } from '@intl/generic';
import { sendCreateNetworkCommand, sendUpdateNetworkCommand } from '@services/transactions';
import { optimisticallyUpdateAllowedNetworks } from '@utils/cache';
import { handleError } from '@utils/errors';
import { getTransactionTimestamp } from '@utils/helpers';

import AccountDetailsModal from './AccountDetailsModal';
import UsernameInput from './UsernameInput';

type Props = {
  closeHandler: () => void;
  network?: Network;
};

const getInitialForm = (network?: Network) => {
  return {
    owner: network?.owner ?? '',
    label: network?.label ?? '',
    isPublic: network?.isPublic ?? false,
    accountsAllowed: network?.accountsAllowed ?? ([] as string[]),
  };
};

function NetworkForm({ network, closeHandler }: Props) {
  const { wallet } = useWallet();
  const client = useQueryClient();
  const { formatMessage } = useIntl();
  const { form, updateForm } = useForm(getInitialForm(network));
  const [usernameInput, setUsernameInput] = useState('');
  const [usernameAccountMap, setUsernameAccountMap] = useState<MapStoreData | null>(null);

  const isUpdate = !!network;

  const handleSubmit = async (e: SyntheticEvent) => {
    e.preventDefault();
    e.stopPropagation();

    let toastMessage = '';

    try {
      if (!wallet) {
        throw Error(formatMessage(noWallet));
      }

      let optimisticResult: Network;

      if (!isUpdate) {
        // Treat as new network
        const command: CreateNetworkCommandProps = {
          label: form.label.toLowerCase(),
          accountsAllowed: form.accountsAllowed,
          isPublic: form.isPublic,
          timestamp: getTransactionTimestamp(),
        };

        const { transactionId } = await sendCreateNetworkCommand(wallet.passphrase, command);
        optimisticResult = { ...command, id: transactionId, owner: wallet.lsk32address };
        toastMessage = formatMessage({ id: 'networks.manage.createdNotification', defaultMessage: 'Network created!' });
      } else {
        // Treat as update
        const command: UpdateNetworkCommandProps = {
          id: network.id,
          accountsAllowed:
            form.owner !== network.owner
              ? [...form.accountsAllowed.filter(a => a !== form.owner), network.owner]
              : form.accountsAllowed,
          isPublic: form.isPublic,
          owner: form.owner,
          timestamp: getTransactionTimestamp(),
        };

        optimisticResult = { ...network, ...command };
        await sendUpdateNetworkCommand(wallet.passphrase, command);
        toastMessage = formatMessage({ id: 'networks.manage.updatedNotification', defaultMessage: 'Network updated!' });
      }

      optimisticallyUpdateAllowedNetworks(client, optimisticResult, wallet.lsk32address);
      closeHandler();
      toast.success(toastMessage);
    } catch (err) {
      handleError(err);
      closeHandler();
    }
  };

  const handleIsPublicCheck = () => {
    updateForm('isPublic', !form.isPublic);
    if (form.isPublic) {
      updateForm('accountsAllowed', network?.accountsAllowed ?? []);
    } else {
      updateForm('isPublic', !form.isPublic);
      updateForm('accountsAllowed', []);
    }
  };

  const handleAddUser = () => {
    if (!usernameAccountMap) {
      return;
    }

    updateForm('accountsAllowed', [...form.accountsAllowed, usernameAccountMap.lsk32address]);
    setUsernameInput('');
  };

  const handleRemoveUser = (value: string) => {
    if (form.owner === value) {
      updateForm('owner', network?.owner ?? wallet?.lsk32address);
    }

    updateForm(
      'accountsAllowed',
      form.accountsAllowed.filter(a => a !== value),
    );
    setUsernameInput('');
  };

  const handleSetOwner = (address: string, isOwner: boolean) => {
    updateForm('owner', isOwner ? address : network?.owner ?? wallet?.lsk32address);
  };

  return (
    <div className="flex justify-center">
      <div className="w-full max-w-md flex flex-col gap-4">
        <form onSubmit={handleSubmit} className="space-y-8">
          <label className="block">
            <Label text={formatMessage(labelText)} />

            <input
              placeholder={formatMessage({
                id: 'networks.manage.form.labelPlaceholder',
                defaultMessage: 'Network Name',
              })}
              value={form.label}
              onChange={e => updateForm('label', e.target.value.toLowerCase())}
              type="text"
              className="base-input"
              disabled={isUpdate}
              required
            />
          </label>

          <label className="block">
            <Label text={formatMessage({ id: 'networks.manage.form.public', defaultMessage: 'Public' })} />

            <div className="mt-2">
              <Toggle isChecked={form.isPublic} onCheck={handleIsPublicCheck} />
            </div>
          </label>

          <label className="block">
            <Label
              text={formatMessage({
                id: 'networks.manage.form.allowedAccounts',
                defaultMessage: 'Allowed Accounts (optional)',
              })}
            />
            <LabelDescription>
              <FormattedMessage
                id="networks.manage.form.allowedAccountsDescription"
                defaultMessage="Determines who is allowed to select the network while uploading"
              />
            </LabelDescription>

            <ul className="pl-6 mt-4">
              {form.accountsAllowed.map(a => (
                <li key={`allowed-account-${a}`} className="list-disc">
                  <div className="flex  gap-2 items-center">
                    <div className="">
                      <AccountDetailsModal address={a} format="text" />
                    </div>

                    <div className="flex gap-2 transition-colors">
                      {isUpdate && (
                        <SmallDivButton
                          onClick={() => handleSetOwner(a, form.owner !== a)}
                          isSelected={form.owner === a}
                        >
                          <Icon type="faCrown" />
                        </SmallDivButton>
                      )}

                      <SmallDivButton onClick={() => handleRemoveUser(a)}>
                        <Icon type="faTrash" />
                      </SmallDivButton>
                    </div>
                  </div>
                </li>
              ))}
            </ul>

            <div className="w-full mt-4">
              <div className="flex items-center gap-2">
                <UsernameInput
                  usernameInput={usernameInput}
                  setUsernameInput={setUsernameInput}
                  setUsernameAccountMap={setUsernameAccountMap}
                  placeholder={
                    form.isPublic
                      ? formatMessage({
                          id: 'networks.manage.form',
                          defaultMessage: 'Option disabled for public networks',
                        })
                      : formatMessage(enterUsername)
                  }
                  disabled={form.isPublic}
                />

                <div className="">
                  <Button
                    color=""
                    type="button"
                    onClick={handleAddUser}
                    disabled={form.isPublic || !usernameAccountMap}
                  >
                    <Icon type="faPlus" />
                  </Button>
                </div>
              </div>
            </div>
          </label>

          {isUpdate && form.owner !== wallet?.lsk32address && (
            <div className="text-sm text-center text-gray-300">
              <Icon type="faCircleInfo" className="mr-1" />
              <FormattedMessage
                id="networks.manage.form.switchingOwnership"
                defaultMessage="Caution: You are about to transfer network ownership."
              />
            </div>
          )}

          <div className="flex justify-center gap-4">
            <Button type="button" color="primary-bordered" onClick={closeHandler}>
              <FormattedMessage {...cancel} />
            </Button>

            <Button type="submit">
              {isUpdate ? <FormattedMessage {...update} /> : <FormattedMessage {...submit} />}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NetworkForm;
