import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState, useTransition } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';

import logoCircle from '@assets/img/logo-circle.png';
import logoFull from '@assets/img/logo-text.png';
import Icon from '@components/ui/Icon';
import useAccountData from '@hooks/useAccountData';
import { devLog, getClasses } from '@utils/helpers';

import LogInButton from './LogInButton';
import NotificationBubble from './NotificationBubble';
import Notifications from './Notifications';

const navigation = [
  {
    name: 'Browse',
    localizedName: <FormattedMessage id="navbar.browse" defaultMessage="Browse" description="Navbar menu item" />,
    href: '/browse',
    requiresAuth: false,
  },
  {
    name: 'Upload',
    localizedName: <FormattedMessage id="navbar.upload" defaultMessage="Upload" description="Navbar menu item" />,
    href: '/upload',
    requiresAuth: true,
  },
  {
    name: 'Transfer',
    localizedName: <FormattedMessage id="navbar.transfer" defaultMessage="Transfer" description="Navbar menu item" />,
    href: '/transfer',
    requiresAuth: true,
  },
  {
    name: 'Collections',
    localizedName: (
      <FormattedMessage id="navbar.collections" defaultMessage="Collections" description="Navbar menu item" />
    ),
    href: '/collections',
    requiresAuth: true,
  },

  {
    name: 'Dashboard',
    localizedName: <FormattedMessage id="navbar.dashboard" defaultMessage="Dashboard" description="Navbar menu item" />,
    href: '/dashboard',
    requiresAuth: true,
  },
];

const MenuDropDownToggle = ({ clickHandler }: { clickHandler: () => void }) => {
  return (
    <button
      data-collapse-toggle="navbar-default"
      type="button"
      onClick={clickHandler}
      className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
      aria-controls="navbar-default"
      aria-expanded="false"
    >
      <span className="sr-only">
        <FormattedMessage id="navbar.open" defaultMessage="Open main menu" />
      </span>
      <svg className="w-6 h-6" aria-hidden="true" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
          clipRule="evenodd"
        ></path>
      </svg>
    </button>
  );
};

const Header = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(true);
  const { account } = useAccountData();
  const { pathname } = useLocation();
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const [pending, startTransition] = useTransition();

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    setMenuIsOpen(false);
  }, [pathname]);

  const isActivePage = (path: string) => {
    return pathname === path;
  };

  const openRequests = account ? account.incomingFileRequests.length + account.incomingCollectionRequests.length : 0;
  const navItems = navigation.filter(n => n.requiresAuth === false || (isAuthenticated && n.requiresAuth));

  const handleNavigate = (href: string) => {
    devLog({ pending });
    startTransition(() => {
      navigate(href);
    });
  };

  const renderMobileHeader = () => {
    return (
      <div className="flex gap-2 flex-wrap mx-auto items-center relative">
        <div className="flex items-center gap-4">
          <MenuDropDownToggle clickHandler={toggleMenu} />
          <div onClick={() => handleNavigate('/')} className="cursor-pointer">
            <img src={logoCircle} alt="logo" className="h-8" />
          </div>
        </div>

        <div
          className={getClasses(menuIsOpen ? '' : 'hidden', 'w-full md:block md:w-auto absolute top-11 left-0 z-50 ')}
          id="navbar-default"
        >
          <ul className="flex flex-col p-4 border border-gray-100 rounded-lg bg-secondary-50 ">
            {navItems.map(nav => (
              <li className="mb-2 md:mb-0 md:mt-1 lg:scale-110" key={nav.name}>
                <div
                  onClick={() => handleNavigate(nav.href)}
                  className={getClasses(
                    isActivePage(nav.href) ? 'underline' : '',
                    'relative underline-offset-2 uppercase decoration-4 active:underline hover:underline decoration-primary-400 text-black z-10 cursor-pointer',
                  )}
                >
                  <div className="z-20">{nav.localizedName}</div>
                </div>
              </li>
            ))}

            <li className="-ml-1 mt-4">
              <LogInButton />
            </li>
          </ul>
        </div>

        <div className="flex justify-end gap-4 grow pr-2">{renderRequestsAndNotifications()}</div>
      </div>
    );
  };

  const renderDesktopHeader = () => {
    return (
      <div className="flex justify-between mx-auto items-center">
        <div onClick={() => handleNavigate('/')} className="cursor-pointer">
          <img src={logoFull} alt="logo" className="h-8 hidden lg:block -mt-2 " />
          <img src={logoCircle} alt="logo" className="m-2 h-10 lg:hidden" />
        </div>

        <MenuDropDownToggle clickHandler={toggleMenu} />

        <div className={getClasses(menuIsOpen ? '' : 'hidden', 'w-full md:block md:w-auto ')} id="navbar-default">
          <ul className="flex items-center p-4 rounded-lg bg-gray-50 bg-transparent flex-row md:space-x-5 lg:space-x-6 xl:space-x-8 md:mt-0 text-sm">
            {navItems.map(nav => (
              <li className="mb-2 md:mb-0  lg:scale-110" key={nav.name}>
                <div
                  onClick={() => handleNavigate(nav.href)}
                  className={getClasses(
                    isActivePage(nav.href) ? 'underline' : '',
                    'relative underline-offset-2 uppercase decoration-4 active:underline hover:underline decoration-primary-400 text-black z-10 cursor-pointer',
                  )}
                >
                  <div className="z-20">{nav.localizedName}</div>
                </div>
              </li>
            ))}

            {renderRequestsAndNotifications()}

            <li className="mt-2 md:mt-0 scale-90">
              <LogInButton />
            </li>
          </ul>
        </div>
      </div>
    );
  };

  const renderRequestsAndNotifications = () => {
    if (!isAuthenticated) {
      return null;
    }

    return (
      <>
        <div className="relative inline-block">
          <div className="cursor-pointer" onClick={() => handleNavigate('/requests')}>
            {openRequests > 0 && <NotificationBubble amount={openRequests} />}
            <Icon type="faEnvelopOutline" />
          </div>
        </div>

        <Notifications />
      </>
    );
  };

  return (
    <header>
      <nav className="bg-white border-gray-200 px-2 sm:px-4 py-2.5 rounded flex justify-center">
        <div className="container">
          <div className="hidden md:block md:w-auto">{renderDesktopHeader()}</div>
          <div className="md:hidden block w-auto">{renderMobileHeader()}</div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
