import { useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@components/ui/Button';
import Modal from '@components/ui/Modal';
import { cancel, save } from '@intl/generic';

type Props = {
  modalIsOpen: boolean;
  handleClose: () => void;
  handleConfirm: (name: string) => void;
  selectedTemplate: string;
};

const SaveTemplateModal = ({ modalIsOpen, handleClose, handleConfirm, selectedTemplate }: Props) => {
  const [templateName, setTemplateName] = useState('');

  const { formatMessage } = useIntl();

  useEffect(() => {
    setTemplateName(selectedTemplate);
  }, [selectedTemplate]);

  const onSubmit = () => {
    handleConfirm(templateName);
    setTemplateName('');
    handleClose();
  };

  const handleCancel = () => {
    setTemplateName('');
    handleClose();
  };

  return (
    <Modal
      title={formatMessage({
        id: 'input.customFields.saveTemplateModal',
        defaultMessage: 'Save Template',
        description: 'Save Template text',
      })}
      isOpen={modalIsOpen}
      handleClose={handleClose}
    >
      <div className="text-center mb-8">
        <input
          placeholder={formatMessage({
            id: 'input.customFields.enterTemplateName',
            defaultMessage: 'Enter template name..',
            description: 'Save template input',
          })}
          value={templateName}
          type="text"
          className="base-input"
          onChange={e => setTemplateName(e.target.value)}
        />
      </div>

      <div className="flex justify-center gap-8">
        <Button type="button" color="primary-bordered" onClick={handleCancel}>
          <FormattedMessage {...cancel} />
        </Button>
        <Button onClick={onSubmit}>
          <FormattedMessage {...save} />
        </Button>
      </div>
    </Modal>
  );
};

export default SaveTemplateModal;
