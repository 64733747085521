import { useAuth0 } from '@auth0/auth0-react';
import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FileWithPath } from 'react-dropzone';
import toast from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import config from '@/config';
import { DownloadPermissionLevel } from '@/types';
import AddressInput, { ADDRESS_RESULT_INIT } from '@components/ui/AddressInput';
import Icon from '@components/ui/Icon';
import Label from '@components/ui/Label';
import LabelDescription from '@components/ui/LabelDescription';
import PageTitle from '@components/ui/PageTitle';
import SuccessScreen from '@components/ui/SuccessScreen';
import Toggle from '@components/ui/Toggle';
import useAccountData from '@hooks/useAccountData';
import { useCollectionData } from '@hooks/useCollectionData';
import useForm from '@hooks/useForm';
import useWallet from '@hooks/useWallet';
import {
  accessPermissionFee,
  collection,
  freelyAccessibleText,
  insufficientFee,
  noWallet,
  optional,
  privateText,
  timedTransfer,
  title,
  transferFee,
} from '@intl/generic';
import { getFileByChecksum } from '@services/api';
import { handleDamUpload, handleLoadingProgress } from '@services/axios';
import { handleError } from '@utils/errors';
import { calculateUploadCost, getExpirationValue, isEmail } from '@utils/helpers';
import { uploadTitle } from 'pages/UploadPage';

import CustomFields, { CustomField } from './CustomFields';
import Drop from './Drop';
import ExpirationSlider from './ExpirationSlider';
import NetworkSelect from './NetworkSelect';
import UploadButton from './UploadButton';
import CostIndicator from './UploadCostDisplay';
import UploadStatus from './UploadStatus';

export const DEFAULT_EXPIRATION = 31536000; // 1 year

export const FORM_INIT = {
  file: null as File | null,
  title: '',
  checksum: '',
  transferFee: 10,
  accessPermissionFee: 1,
  isPrivate: false,
  isTimedTransfer: false,
  downloadPermissionLevel: 'standard' as DownloadPermissionLevel,
  collectionId: '',
  expiration: DEFAULT_EXPIRATION,
  discount: 0,
  network: 'primary',
};

const Upload = () => {
  const { wallet } = useWallet();
  const { isAuthenticated, user } = useAuth0();
  const { formatMessage } = useIntl();
  const { account } = useAccountData();
  const { collections } = useCollectionData(account?.collectionsOwned ?? [], { limit: -1 }, [
    'account',
    'collectionsOwned',
  ]);

  const { form, updateForm, resetForm } = useForm(FORM_INIT);
  const [addressResult, setAddressResult] = useState(cloneDeep(ADDRESS_RESULT_INIT));
  const [customFields, setCustomFields] = useState<CustomField[]>([]);

  const [success, setSuccess] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState('');
  const [loadingProgress, setLoadingProgress] = useState(0);

  const handleFileChange = async (newFile?: FileWithPath, newChecksum?: string) => {
    updateForm('file', newFile || null);
    updateForm('checksum', newChecksum || '');

    if (newFile) {
      updateForm('title', newFile.name.split('.')[0] || 'New file');
    }
  };

  const handleDownloadPermissionLevelChange = () => {
    if (form.downloadPermissionLevel === 'standard') {
      updateForm('downloadPermissionLevel', 'public');
      updateForm('isPrivate', false);
      updateForm('isTimedTransfer', false);
      updateForm('accessPermissionFee', 0);
    } else {
      updateForm('downloadPermissionLevel', 'standard');
      updateForm('accessPermissionFee', FORM_INIT.accessPermissionFee);
    }
  };

  const handleTimedTransferChange = () => {
    if (form.isTimedTransfer) {
      updateForm('isTimedTransfer', false);
      updateForm('expiration', DEFAULT_EXPIRATION);
    } else {
      updateForm('isTimedTransfer', true);
      updateForm('expiration', config.timedTransferExpiration);
    }
  };

  /** Prevent user file size abuse */
  useEffect(() => {
    if (!form.isTimedTransfer && form.file && form.file.size > config.maxUploadFileSize) {
      updateForm('file', null);
    }

    // eslint-disable-next-line
  }, [form.isTimedTransfer, form.file]);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const {
      file,
      checksum,
      title,
      isPrivate,
      transferFee,
      accessPermissionFee,
      isTimedTransfer,
      downloadPermissionLevel,
      collectionId,
      discount,
      expiration: rawExpiration,
      network,
    } = form;

    const expiration = getExpirationValue(rawExpiration, isTimedTransfer);

    try {
      if (!wallet) {
        throw new Error(formatMessage(noWallet));
      }

      if (!file) {
        throw new Error(
          formatMessage({
            id: 'upload.error.noFile',
            defaultMessage: 'No file selected',
            description: 'No file error message',
          }),
        );
      }

      const uploadCost = calculateUploadCost(
        expiration,
        file?.size ?? 0,
        isTimedTransfer ? 0 : discount,
        form.isTimedTransfer,
      );

      if (transferFee < 1) {
        throw new Error(formatMessage(insufficientFee));
      }

      const fileExists = await getFileByChecksum(checksum);

      if (fileExists) {
        throw new Error(
          formatMessage({
            id: 'upload.error.fileAlreadyExists',
            defaultMessage: 'File already exists',
            description: 'File already exists error message',
          }),
        );
      }

      if (isTimedTransfer && !addressResult.emailHash) {
        throw new Error(
          formatMessage({
            id: 'upload.error.invalidRecipient',
            defaultMessage: 'Invalid recipient present',
            description: 'Invalid recipient error message',
          }),
        );
      }

      const formData = new FormData();

      formData.append('passphrase', wallet.passphrase);
      formData.append('expiration', expiration.toString());
      formData.append('network', network);
      formData.append('file', file, file.name);
      formData.append('title', title);

      formData.append('isTimedTransfer', `${isTimedTransfer}`);
      formData.append('isPrivate', `${isPrivate}`);
      formData.append('newUser', `${!!!addressResult.account}`);

      formData.append(
        'recipientEmailHash',
        addressResult.account ? addressResult.account.emailHash : addressResult.emailHash,
      );
      formData.append('collectionId', collectionId);
      formData.append('downloadPermissionLevel', downloadPermissionLevel);
      formData.append('customFields', JSON.stringify(customFields));
      formData.append('transferFee', transferFee.toString());
      formData.append('accessPermissionFee', accessPermissionFee.toString());
      formData.append('discount', discount.toString());
      formData.append('fee', uploadCost.fee.toString());
      formData.append('senderEmail', user?.email ?? '');
      formData.append('recipientEmail', isEmail(addressResult.rawInput) ? addressResult.rawInput : '');

      setLoadingStatus('Uploading file');

      await handleDamUpload(formData, {
        onUploadProgress: e => handleLoadingProgress(e, setLoadingProgress),
      });

      setLoadingStatus(
        formatMessage({
          id: 'upload.addingFile',
          defaultMessage: 'Adding file to blockchain',
          description: 'Adding file status message',
        }),
      );

      const successMessage = isTimedTransfer
        ? formatMessage({
            id: 'upload.timedTransferSubmitted',
            defaultMessage: 'Timed Transfer submitted!',
            description: 'Success message',
          })
        : formatMessage({
            id: 'upload.fileUploaded',
            defaultMessage: 'File uploaded!',
            description: 'Success message',
          });

      toast.success(successMessage);
      setLoadingStatus('');
      setSuccess(true);
    } catch (err) {
      setLoadingStatus('');
      handleError(err);
    }
  };

  const handleReset = () => {
    setSuccess(false);
    setCustomFields([]);
    setAddressResult(cloneDeep(ADDRESS_RESULT_INIT));
    resetForm();
  };

  if (!success && loadingStatus) {
    return <UploadStatus heading={loadingStatus} progress={loadingProgress} />;
  }

  if (success) {
    return <SuccessScreen reset={handleReset} type="Upload" />;
  }

  if (!wallet) {
    return null;
  }

  const cost = calculateUploadCost(
    form.expiration,
    form.file?.size ?? 0,
    form.discount,
    form.isTimedTransfer,
  ).tokenAmount;

  return (
    <>
      <PageTitle text={formatMessage(uploadTitle)} />

      <form onSubmit={handleSubmit} className="space-y-8 md:space-y-10 lg:space-y-12">
        <Drop file={form.file} handleFileChange={handleFileChange} isTimedTransfer={form.isTimedTransfer} />

        <div className="gap-4 md:gap-6 lg:gap-8 grid grid-cols-2 md:grid-cols-3">
          <label>
            <Label text={formatMessage(timedTransfer)} />

            <LabelDescription>
              <FormattedMessage
                id="upload.timedTransferDescription"
                defaultMessage="Expires after {days} days"
                values={{ days: Math.round(config.timedTransferExpiration / 86400) }}
              />
            </LabelDescription>

            <div className="mt-2">
              <Toggle
                isChecked={form.isTimedTransfer}
                onCheck={handleTimedTransferChange}
                disabled={form.downloadPermissionLevel === 'public'}
              />
            </div>
          </label>

          <label>
            <Label text={formatMessage(privateText)} />
            <LabelDescription>
              <FormattedMessage id="upload.privateDescription" defaultMessage="Hidden from public pages" />
            </LabelDescription>

            <div className="mt-2">
              <Toggle
                isChecked={form.isPrivate}
                onCheck={() => updateForm('isPrivate', !form.isPrivate)}
                disabled={form.downloadPermissionLevel === 'public'}
              />
            </div>
          </label>

          <label>
            <Label text={formatMessage(freelyAccessibleText)} />
            <LabelDescription>
              <FormattedMessage id="upload.freelyAccessibleDescription" defaultMessage="Downloadable by anyone" />
            </LabelDescription>

            <div className="mt-2">
              <Toggle
                isChecked={form.downloadPermissionLevel === 'public'}
                onCheck={handleDownloadPermissionLevelChange}
              />
            </div>
          </label>
        </div>

        {!form.isTimedTransfer && (
          <ExpirationSlider updateForm={updateForm} value={form.expiration} disabled={!form.file} />
        )}

        <CostIndicator cost={cost} discount={form.discount} fileIsSelected={!!form.file} />

        <label className="block">
          <Label text={formatMessage(title)} />
          <input
            placeholder={formatMessage({
              id: 'upload.enterTitle',
              defaultMessage: 'Enter a title',
              description: 'Label text',
            })}
            value={form.title}
            onChange={e => updateForm('title', e.target.value)}
            type="text"
            className="base-input"
            required
          />
        </label>

        <div className="flex gap-4 md:gap-6 lg:gap-8">
          <label className="block w-1/2">
            <Label text={formatMessage(transferFee)} />
            <div>
              <input
                min={1}
                id="transfer-input"
                type="number"
                value={form.transferFee}
                onChange={e => updateForm('transferFee', Number(e.target.value))}
                className="base-input mb-1 block"
              />
            </div>
          </label>
          <label className="block w-1/2">
            <Label text={formatMessage(accessPermissionFee)} />
            <div>
              <input
                id="access-input"
                type="number"
                value={form.accessPermissionFee}
                onChange={e => updateForm('accessPermissionFee', Number(e.target.value))}
                className="base-input mb-1 block"
                disabled={form.isTimedTransfer || form.downloadPermissionLevel === 'public'}
              />
            </div>
          </label>
        </div>

        {!form.isTimedTransfer && (
          <label className="block space-y-1">
            <Label text={`${formatMessage(collection)} (${formatMessage(optional)})`} />

            {collections.length > 0 && (
              <select
                className="base-input"
                value={form.collectionId}
                onChange={e => updateForm('collectionId', e.target.value)}
              >
                <option value="">
                  <FormattedMessage id="upload.noCollectionSelected" defaultMessage="No collection selected" />
                </option>
                {collections.map(c => (
                  <option value={c.id} key={c.id}>
                    {c.title}
                  </option>
                ))}
              </select>
            )}

            {!collections.length && (
              <LabelDescription>
                <FormattedMessage id="upload.noCollectionsFound" defaultMessage="No collection found" />.{' '}
                <Link to="/collections">
                  <FormattedMessage id="upload.createOne" defaultMessage="Create one" /> <Icon type="faArrowRight" />
                </Link>
              </LabelDescription>
            )}
          </label>
        )}

        <NetworkSelect
          value={form.network}
          onChangeHandler={val => updateForm('network', val)}
          lsk32address={wallet.lsk32address}
        />

        {form.isTimedTransfer && <AddressInput setAddressResult={setAddressResult} isTimedTransfer />}
        {!form.isPrivate && <CustomFields customFields={customFields} setCustomFields={setCustomFields} />}

        <UploadButton
          isAuthenticated={isAuthenticated}
          isTimedTransfer={form.isTimedTransfer}
          wallet={wallet}
          form={form}
          uploadCost={cost}
        />
      </form>
    </>
  );
};

export default Upload;
