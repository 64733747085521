import { useAuth0 } from '@auth0/auth0-react';
import { lazy, Suspense } from 'react';
import { Routes as BrowserRoutes, Outlet, Route } from 'react-router-dom';

import useAccountData from '@hooks/useAccountData';
import BrowsePage from 'pages/BrowsePage';
import ForbiddenPage from 'pages/ForbiddenPage';
import LandingPage from 'pages/LandingPage';
import LoadingPage from 'pages/LoadingPage';
import ServiceUnavailable from 'pages/ServiceUnavailablePage';
import TosPage from 'pages/TosPage';
import ViewPage from 'pages/ViewPage';
import WelcomePage from 'pages/WelcomePage';

import { useClientStatusStore } from '@stores/useClientStatusStore';

const CollectionsPage = lazy(() => import('pages/CollectionsPage'));
const DashboardPage = lazy(() => import('pages/DashboardPage'));
const HomePage = lazy(() => import('pages/HomePage'));
const UnauthorizedPage = lazy(() => import('pages/UnauthorizedPage'));
const EmailNotVerifiedPage = lazy(() => import('pages/EmailNotVerified'));
const NotFoundPage = lazy(() => import('pages/NotFoundPage'));
const RequestsPage = lazy(() => import('pages/RequestsPage'));
const TransferCollectionPage = lazy(() => import('pages/TransferCollectionPage'));
const TransferFilePage = lazy(() => import('pages/TransferFilePage'));
const TransferTokensPage = lazy(() => import('pages/TransferTokensPage'));
const TransferPage = lazy(() => import('pages/TransferPage'));
const UploadPage = lazy(() => import('pages/UploadPage'));
const RegisterPage = lazy(() => import('pages/RegisterPage'));
const PurchaseTokensPage = lazy(() => import('pages/PurchaseTokensPage'));
const CheckoutPage = lazy(() => import('pages/CheckoutPage'));

const AuthenticatedRoute = ({
  isAuthenticated,
  emailVerified,
  children,
}: {
  isAuthenticated: boolean;
  emailVerified?: boolean;
  children?: any;
}) => {
  if (!isAuthenticated) {
    return <UnauthorizedPage />;
  }

  if (!emailVerified) {
    return <EmailNotVerifiedPage />;
  }

  return children ?? <Outlet />;
};

const RegisteredUserRoute = ({
  isAllowed,
  clientIsOnline,
  children,
}: {
  isAllowed: boolean;
  clientIsOnline: boolean | null;
  children?: any;
}) => {
  if (!isAllowed && clientIsOnline === null) {
    return <LoadingPage />;
  }

  if (!isAllowed && clientIsOnline === false) {
    return <ServiceUnavailable />;
  }

  if (!isAllowed) {
    return <ForbiddenPage />;
  }

  return children ?? <Outlet />;
};

const Routes = () => {
  const { isAuthenticated, user } = useAuth0();
  const { account } = useAccountData();
  const { clientIsOnline } = useClientStatusStore();

  const accountExists = !!account?.emailHash;
  const emailVerified = !!user?.email_verified;

  return (
    <Suspense fallback={null}>
      <BrowserRoutes>
        <Route path="/" element={<HomePage />} />
        <Route path="/browse" element={<BrowsePage />} />
        <Route path="/view/:id" element={<ViewPage />} />
        <Route path="/welcome" element={<WelcomePage />} />
        <Route path="/landing" element={<LandingPage />} />
        <Route path="/terms-and-conditions" element={<TosPage />} />
        <Route path="*" element={<NotFoundPage />} />

        <Route element={<AuthenticatedRoute isAuthenticated={isAuthenticated} emailVerified={emailVerified} />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/register" element={<RegisterPage />} />

          <Route element={<RegisteredUserRoute clientIsOnline={clientIsOnline} isAllowed={accountExists} />}>
            <Route path="/upload" element={<UploadPage />} />
            <Route path="/transfer" element={<TransferPage />} />
            <Route path="/transfer/tokens" element={<TransferTokensPage />} />
            <Route path="/transfer/file" element={<TransferFilePage />} />
            <Route path="/transfer/collection" element={<TransferCollectionPage />} />
            <Route path="/collections" element={<CollectionsPage />} />
            <Route path="/requests" element={<RequestsPage />} />
            <Route path="/purchase" element={<PurchaseTokensPage />} />
            <Route path="/checkout" element={<CheckoutPage />} />
          </Route>
        </Route>
      </BrowserRoutes>
    </Suspense>
  );
};

export default Routes;
