import { defineMessage, useIntl } from 'react-intl';

import SEO from '@components/ui/SEO';
import Upload from '@components/upload';

export const uploadTitle = defineMessage({
  id: 'upload.header',
  description: 'Page header for upload page',
  defaultMessage: 'Upload',
});

const UploadPage = () => {
  const { formatMessage } = useIntl();

  return (
    <section className="container max-w-4xl">
      <SEO pageTitle={formatMessage(uploadTitle)} />
      <Upload />
    </section>
  );
};

export default UploadPage;
