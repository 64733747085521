import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from '@components/ui/Button';
import Icon from '@components/ui/Icon';
import Modal from '@components/ui/Modal';
import useAllowedNetworks from '@hooks/useAllowedNetworks';
import useWallet from '@hooks/useWallet';
import { close } from '@intl/generic';

import NetworkForm from './NetworkForm';
import NetworkTable from './NetworkTable';

type ManageNetworkModalScreen = 'table' | 'createForm' | 'updateForm';

export default function ManageNetworksModal() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { wallet } = useWallet();
  const { data } = useAllowedNetworks(wallet?.lsk32address);
  const { formatMessage } = useIntl();
  const [screen, setScreen] = useState<ManageNetworkModalScreen>('table');
  const [selectedNetwork, setSelectedNetwork] = useState('');

  const handleSelectNetworkUpdate = (networkId: string) => {
    setScreen('updateForm');
    setSelectedNetwork(networkId);
  };

  const network = data?.find(n => n.id === selectedNetwork);

  const ownedNetworks = data?.filter(n => n.owner === wallet?.lsk32address) ?? [];

  return (
    <>
      <Modal
        title={formatMessage({ id: 'networks.modalTitle', defaultMessage: 'Manage Networks' })}
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
      >
        {screen === 'table' && (
          <>
            <NetworkTable data={ownedNetworks} handleSelectNetworkUpdate={handleSelectNetworkUpdate} />{' '}
            <div className="flex justify-center gap-4 mt-8">
              <Button color="primary-bordered" onClick={() => setModalIsOpen(false)}>
                <FormattedMessage {...close} />
              </Button>

              <Button color="primary" onClick={() => setScreen('createForm')}>
                <FormattedMessage id="networks.createNetwork" defaultMessage="Create Network" />
              </Button>
            </div>
          </>
        )}
        {screen === 'updateForm' && <NetworkForm network={network} closeHandler={() => setScreen('table')} />}
        {screen === 'createForm' && <NetworkForm closeHandler={() => setScreen('table')} />}
      </Modal>

      <Button onClick={() => setModalIsOpen(true)} link>
        <FormattedMessage id="networks.manage" defaultMessage="Manage Networks" /> <Icon type="faArrowRight" />
      </Button>
    </>
  );
}
