import { AccountProps, Collection } from '@/types';

export const generateDefaultAccount = (address: string): AccountProps => {
  const account: AccountProps = {
    filesOwned: [],
    filesAllowed: [],
    incomingFileRequests: [],
    outgoingFileRequests: [],
    collectionsOwned: [],
    collectionsAllowed: [],
    incomingCollectionRequests: [],
    outgoingCollectionRequests: [],
    username: '',
    emailHash: '',
    type: 'unknown',
    companyDetails: {
      name: '',
      vat: '',
    },
    personalDetails: {
      surname: '',
      name: '',
      fiscalCode: '',
    },
    address: Buffer.from(''),
    token: {
      balance: BigInt(0),
    },
  };

  return account;
};

export const createDummyCollection = (
  id: string,
  owner: Buffer,
  collectionData: { title: string; transferFee: number; accessPermissionFee: number; fileIds: string[] },
): Collection => {
  const { title, transferFee, accessPermissionFee, fileIds } = collectionData;

  return {
    id,
    title,
    transferFee,
    accessPermissionFee,
    owner,
    fileIds,
    requests: [],
    downloadPermissionLevel: 'standard',
  };
};
