import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';

import { Collection, DamFile } from '@/types';
import Button from '@components/ui/Button';
import Icon from '@components/ui/Icon';
import Modal from '@components/ui/Modal';
import { DEFAULT_EXPIRATION } from '@components/upload';
import ExpirationSlider from '@components/upload/ExpirationSlider';
import CostIndicator from '@components/upload/UploadCostDisplay';
import useForm from '@hooks/useForm';
import useWallet from '@hooks/useWallet';
import { cancel, submit } from '@intl/generic';
import { sendExtendExpirationCommand } from '@services/axios';
import { optimisticallyUpdateExpirations } from '@utils/cache';
import { handleError } from '@utils/errors';
import { calculateUploadCost, getExpirationValue } from '@utils/helpers';

import ExpirationIndicator from './ExpirationIndicator';

const FORM_INIT = {
  expiration: DEFAULT_EXPIRATION,
  discount: 0,
};

type Props =
  | {
      type: 'collection';
      collection: Collection;
      files: DamFile[];
    }
  | {
      type: 'file';
      file: DamFile;
    };

const ExtendExpirationModal = (props: Props) => {
  const { wallet } = useWallet();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { form, updateForm, resetForm } = useForm(FORM_INIT);
  const queryClient = useQueryClient();
  const { formatMessage } = useIntl();

  const isCollectionType = props.type === 'collection';

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if (!wallet) {
      return;
    }

    const expiration = getExpirationValue(form.expiration, false);
    const fileIds = isCollectionType ? props.collection.fileIds : [props.file.data.id];

    mutate({ passphrase: wallet.passphrase, fileIds, amount: expiration });
  };

  const { mutate } = useMutation({
    mutationFn: (data: { passphrase: string; fileIds: string[]; amount: number }) => sendExtendExpirationCommand(data),
    onSuccess: async res => {
      toast.success(
        formatMessage({
          id: 'expiration.expirationsUpdated',
          defaultMessage: 'Expirations updated!',
        }),
      );
      optimisticallyUpdateExpirations(queryClient, res.data.asset);
    },
    onError: handleError,
    onSettled: () => {
      setModalIsOpen(false);
      resetForm();
    },
  });

  const renderDescription = () => {
    if (isCollectionType) {
      return (
        <FormattedMessage
          id="expiration.extendCollectionExpirationDescription"
          defaultMessage="Extend the expiration of all files in collection: {collection}"
          values={{ collection: <span className="font-bold">{props.collection.title}</span> }}
        />
      );
    }

    return (
      <FormattedMessage
        id="expiration.extendFileExpirationDescription"
        defaultMessage="Extend the expiration file: {file}"
        values={{ file: <span className="font-bold">{props.file.data.title}</span> }}
      />
    );
  };

  const renderExpirationIndication = () => {
    if (isCollectionType) {
      return props.files.map(file => (
        <ExpirationIndicator key={file.data.id} file={file} expirationExtension={form.expiration} />
      ));
    }

    return <ExpirationIndicator file={props.file} expirationExtension={form.expiration} />;
  };

  const getFileSize = isCollectionType
    ? props.files.reduce((prev, current) => (prev += current.data.size), 0)
    : props.file.data.size;
  const cost = calculateUploadCost(form.expiration, getFileSize, form.discount, false).tokenAmount;

  return (
    <>
      <div className="text-center">
        <Button link onClick={() => setModalIsOpen(true)}>
          <Icon type="faClock" />
        </Button>
      </div>

      <Modal
        title={formatMessage({
          id: 'expiration.extendExpirationModal',
          defaultMessage: 'Extend Expiration',
          description: 'Modal header',
        })}
        isOpen={modalIsOpen}
        handleClose={() => setModalIsOpen(false)}
      >
        <form onSubmit={handleSubmit}>
          <div className="space-y-12">
            {renderDescription()}

            <ExpirationSlider updateForm={updateForm} value={form.expiration} />
            <CostIndicator cost={cost} discount={form.discount} fileIsSelected={true} />

            <div className="flex justify-center">
              <div className="w-full text-sm">{renderExpirationIndication()}</div>
            </div>

            <div className="flex justify-center gap-4 pt-4 ">
              <Button type="button" color="primary-bordered" onClick={() => setModalIsOpen(false)}>
                <FormattedMessage {...cancel} />
              </Button>

              <Button type="submit">
                <FormattedMessage {...submit} />
              </Button>
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ExtendExpirationModal;
