import { ReactElement } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';

import { sender } from '@intl/generic';
import { HistoryItem, HistoryItemType } from '@/types';
import { displayDateTime } from '@utils/formatting';

import AccountDetailsModal from '../shared/AccountDetailsModal';

type Props = {
  item: HistoryItem;
};

const transferredTo = defineMessage({
  id: 'view.history.transferredTo',
  defaultMessage: 'Transferred to',
  description: 'History item text',
});

const contentMap: {
  [key in HistoryItemType]: {
    title: ReactElement;
    body: ReactElement;
    color: string;
  };
} = {
  [HistoryItemType.Transfer]: {
    title: (
      <FormattedMessage
        id="view.history.fileTransfer"
        defaultMessage="File Transfer"
        description="History item title"
      />
    ),
    color: 'text-blue-400',
    body: <FormattedMessage {...transferredTo} />,
  },
  [HistoryItemType.AccessPermission]: {
    title: (
      <FormattedMessage
        id="view.history.accessPermissionGranted"
        defaultMessage="Access Permission Granted"
        description="History item title"
      />
    ),
    color: 'text-orange-400',
    body: <FormattedMessage id="view.history.grantedTo" defaultMessage="Granted to" description="History item text" />,
  },
  [HistoryItemType.Registration]: {
    title: (
      <FormattedMessage
        id="view.history.fileRegistered"
        defaultMessage="File Registered"
        description="History item title"
      />
    ),
    color: 'text-green-400',
    body: <FormattedMessage id="view.history.owner" defaultMessage="Owner" description="History item text" />,
  },
  [HistoryItemType.TimedTransferSubmission]: {
    title: (
      <FormattedMessage
        id="view.history.timedTransferInitiated"
        defaultMessage="Timed Transfer Initiated"
        description="History item title"
      />
    ),
    color: 'text-purple-400',
    body: <FormattedMessage {...sender} />,
  },
  [HistoryItemType.TimedTransferResponse]: {
    title: (
      <FormattedMessage
        id="view.history.timedTransferCompleted"
        defaultMessage="Timed Transfer Completed"
        description="History item title"
      />
    ),
    color: 'text-cyan-400',
    body: <FormattedMessage {...transferredTo} />,
  },
  [HistoryItemType.AddedToCollection]: {
    title: (
      <FormattedMessage
        id="view.history.addedToCollection"
        defaultMessage="Added to Collection"
        description="History item title"
      />
    ),
    color: 'text-lime-400',
    body: <FormattedMessage id="view.history.addedBy" defaultMessage="Added by" description="History item text" />,
  },
  [HistoryItemType.RemovedFromCollection]: {
    title: (
      <FormattedMessage
        id="view.history.removedFromCollection"
        defaultMessage="Removed from Collection"
        description="History item title"
      />
    ),
    color: 'text-indigo-400',
    body: <FormattedMessage id="view.history.removedBy" defaultMessage="Removed by" description="History item text" />,
  },
  [HistoryItemType.TransferredViaCollection]: {
    title: (
      <FormattedMessage
        id="view.history.transferredViaCollection"
        defaultMessage="Transferred via Collection"
        description="History item title"
      />
    ),
    color: 'text-pink-400',
    body: <FormattedMessage {...transferredTo} />,
  },
};

const fallBack = {
  title: (
    <FormattedMessage
      id="view.history.unknownActivity"
      defaultMessage="Unknown Activity"
      description="History item title"
    />
  ),
  color: 'text-yellow-400',
  body: 'Account: ',
};

const FileHistoryItem = ({ item }: Props) => {
  const content = contentMap[item.activity] || fallBack;

  return (
    <div className="flex mb-6">
      <div className={`mr-4 ${content.color}`}>○</div>

      <div className="flex flex-col truncate">
        <div className="font-semibold">{content.title}</div>
        <div className="text-xs text-gray-400 mb-2">{displayDateTime(item.createdAt.human)}</div>

        <div className="text-sm ">
          {content.body}: <AccountDetailsModal address={item.userAddress} format="button" />
        </div>
      </div>
    </div>
  );
};

export default FileHistoryItem;
