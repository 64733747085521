import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { NotificationItem, NotificationType } from '@/types';
import Icon from '@components/ui/Icon';
import useClickOutsideComponent from '@hooks/useClickOutsideComponent';
import useWallet from '@hooks/useWallet';
import { getNotifications, setReadAllStatus, setReadStatus } from '@services/api';
import { optimisticallyRemoveAllNotifications, optimisticallyRemoveNotification } from '@utils/cache';
import { displayDateTimeFromSeconds } from '@utils/formatting';
import { devLog } from '@utils/helpers';
import { FormattedMessage } from 'react-intl';

import NotificationBubble from './NotificationBubble';

const notificationTypeLookup: { [key in NotificationType]: number } = {
  '1-day': 1,
  '15-day': 15,
  '30-day': 30,
  expired: 0,
  removed: 0,
};

function Notification({ notification, handleClick }: { notification: NotificationItem; handleClick: () => void }) {
  const renderMessage = () => {
    if (notification.type === 'expired') {
      return (
        <FormattedMessage
          id="notification.fileExpired"
          defaultMessage="{file} has expired"
          values={{
            file: <div className="font-bold inline">{notification.fileTitle}</div>,
          }}
        />
      );
    }

    if (notification.type === 'removed') {
      return (
        <FormattedMessage
          id="notification.fileRemoved"
          defaultMessage="{file} is removed"
          values={{
            file: <div className="font-bold inline">{notification.fileTitle}</div>,
          }}
        />
      );
    }

    return (
      <FormattedMessage
        id="notification.fileIsExpiring"
        defaultMessage="{file} expires in {days} day(s)"
        values={{
          file: <div className="font-bold inline">{notification.fileTitle}</div>,
          days: notificationTypeLookup[notification.type],
        }}
      />
    );
  };

  const isExtendable = notification.type !== 'removed';

  return (
    <div
      className="block px-4 py-2 text-sm text-gray-700 cursor-pointer"
      tabIndex={-1}
      id="menu-item-0"
      onClick={handleClick}
    >
      {isExtendable ? (
        <Link to={`/view/${notification.fileId}`}>{renderMessage()}</Link>
      ) : (
        <span className="text-secondary-400">{renderMessage()}</span>
      )}
      <div className="text-xs text-gray-300">{displayDateTimeFromSeconds(notification.timestamp)}</div>
    </div>
  );
}

export default function Notifications() {
  const ref = useRef(null);
  const [dropdownIsOpen, setDropDownIsOpen] = useState(false);

  const { wallet } = useWallet();
  const { pathname } = useLocation();
  const queryClient = useQueryClient();
  useClickOutsideComponent(ref, () => setDropDownIsOpen(false));

  useEffect(() => {
    setDropDownIsOpen(false);
  }, [pathname]);

  const { data } = useQuery({
    queryKey: ['notifications', wallet?.lsk32address ?? 'tmp'],
    queryFn: () => getNotifications(wallet?.lsk32address),
    refetchInterval: 10000,
    keepPreviousData: true,
    staleTime: 10000,

    onSuccess: () => devLog('Account notifications fetched'),
  });

  const notifications = Array.isArray(data) ? data : [];

  const handleClick = async (notification: NotificationItem) => {
    setDropDownIsOpen(false);

    if (!wallet) {
      return;
    }

    await setReadStatus(wallet?.lsk32address, notification.fileId, notification.type);
    optimisticallyRemoveNotification(queryClient, wallet.lsk32address, notification.fileId, notification.type);
  };

  const handleReadAllStatus = async () => {
    setDropDownIsOpen(false);

    if (!wallet) {
      return;
    }

    await setReadAllStatus(wallet?.lsk32address);
    optimisticallyRemoveAllNotifications(queryClient, wallet.lsk32address);
  };

  const noNotificationsPresent = !notifications || notifications?.length === 0;

  return (
    <div className="relative inline-block" ref={ref}>
      <div className="cursor-pointer" onClick={() => setDropDownIsOpen(!dropdownIsOpen)}>
        {!noNotificationsPresent && <NotificationBubble amount={notifications?.length ?? 0} />}

        <Icon type="faBell" />
      </div>

      {dropdownIsOpen && (
        <div
          className="absolute right-0 z-10 mt-2 w-64  origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex={-1}
        >
          <div className="py-1" role="none">
            <div className="px-4 py-2 border-b-2 text-center font-bold">
              <FormattedMessage id="notifications.title" defaultMessage="Expiration Notifications" />
            </div>
            <div className="max-h-96 max-w-64 overflow-y-auto">
              {noNotificationsPresent && (
                <div className="px-4 py-2 text-center text-gray-300">
                  <FormattedMessage id="notifications.noNotifications" defaultMessage="No notifications" />
                </div>
              )}

              {notifications?.map(n => (
                <Notification key={`${n.fileId}-${n.timestamp}`} notification={n} handleClick={() => handleClick(n)} />
              ))}

              {!noNotificationsPresent && (
                <div
                  className="px-4 py-2  text-gray-300 cursor-pointer hover:text-gray-400 mt-2"
                  onClick={handleReadAllStatus}
                >
                  <FormattedMessage id="notifications.setReadAll" defaultMessage="Mark all as read" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
