import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import Button from '@components/ui/Button';
import SEO from '@components/ui/SEO';
import { finishRegistration, forbidden } from '@intl/generic';

const ForbiddenPage = () => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <div className="flex justify-center items-center h-full">
      <SEO pageTitle="403" pageDescription={`403 - ${formatMessage(forbidden)}`} />

      <div className="text-center space-y-4">
        <h1>
          403 - <FormattedMessage {...forbidden} />
        </h1>
        <p className="mb-4">
          <FormattedMessage
            id="error.403.finishRegistrationDescription"
            defaultMessage="Whoops! Please finish registration to view this page."
            description="Error description"
          />
        </p>
        <Button onClick={() => navigate('/register')}>
          <FormattedMessage {...finishRegistration} />
        </Button>
      </div>
    </div>
  );
};

export default ForbiddenPage;
