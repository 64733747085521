const config = {
  projectTitle: process.env.REACT_APP_PROJECT_TITLE ?? 'Project',
  projectDomain: process.env.REACT_APP_PROJECT_DOMAIN ?? 'https://app.verifica.srl',
  blockchainApi: process.env.REACT_APP_BLOCKCHAIN_API ?? 'ws://localhost:8080/ws',
  damApi: process.env.REACT_APP_DAM_API ?? 'http://localhost:11001',
  backend: `${process.env.REACT_APP_BACKEND ?? 'http://localhost:4445'}/api/v1`,
  gitSha: process.env.REACT_APP_GIT_SHA,
  version: process.env.REACT_APP_VERSION,
  maxUploadFileSize: Number(process.env.REACT_APP_MAX_UPLOAD_FILE_SIZE ?? 50000000),
  maxTimedTransferFileSize: Number(process.env.REACT_APP_MAX_TIMED_TRANSFER_FILE_SIZE ?? 50000000),
  timedTransferExpiration: 604800, // 7 days in seconds,
  auth0Domain: process.env.REACT_APP_AUTH0_DOMAIN ?? 'fallback',
  auth0ClientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? 'fallback',
  itemsPerPage: Number(process.env.REACT_APP_ITEMS_PER_PAGE ?? 10),
  derivationPath: "m/44'/134'/0'",
  tokenId: process.env.REACT_APP_TOKEN_ID ?? '0199999900000000',
  baseFee: BigInt('10000000'), // 0.1 token(s)
  regularPricePerMbPerYear: 1, // Base price in tokens
  timedTransferPremium: 2, // Multiplier for price calculation
  expirationDiscount: 0, // 3 % per year starting from 1 year onwards
  devMode: process.env.REACT_APP_DEV_MODE ? process.env.REACT_APP_DEV_MODE === 'true' : false, // Use different expiration values for testing
};

export default config;
