import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import config from '@/config';
import Label from '@components/ui/Label';
import { expiration, nMonths, nYears, oneMonth, oneYear } from '@intl/generic';

const getExpirationOption = (months: number) => {
  const expiration = months * 2628000;

  let discount = 0;

  if (months === 1) {
    return {
      label: <FormattedMessage {...oneMonth} />,
      expiration,
      discount,
    };
  }

  if (months < 12) {
    return {
      label: <FormattedMessage {...nMonths} {...{ values: { months } }} />,
      expiration,
      discount,
    };
  }

  if (months === 12) {
    return {
      label: <FormattedMessage {...oneYear} />,
      expiration,
      discount,
    };
  }

  discount = ((months - 12) / 12) * config.expirationDiscount; // 3 % discount per year

  return {
    label: <FormattedMessage {...nYears} {...{ values: { years: months / 12 } }} />,
    expiration,
    discount,
  };
};

const expirationOptions = [
  getExpirationOption(1),
  getExpirationOption(3),
  getExpirationOption(6),
  getExpirationOption(1 * 12),
  getExpirationOption(2 * 12),
  getExpirationOption(3 * 12),
  getExpirationOption(4 * 12),
  getExpirationOption(5 * 12),
  getExpirationOption(6 * 12),
  getExpirationOption(7 * 12),
  getExpirationOption(8 * 12),
  getExpirationOption(9 * 12),
  getExpirationOption(10 * 12),
];

type Props = {
  updateForm: (field: 'expiration' | 'discount', value: number) => void;
  value: number;
  disabled?: boolean;
};

export default function ExpirationSlider({ updateForm, value, disabled }: Props) {
  const { formatMessage } = useIntl();
  const [selectedOption, setSelectedOption] = useState(expirationOptions.findIndex(o => o.expiration === value));

  const expirationOption = expirationOptions[selectedOption];

  const handleExpirationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    updateForm('expiration', expirationOption.expiration);
    updateForm('discount', expirationOption.discount);
    setSelectedOption(value);
  };

  return (
    <label htmlFor="steps-range" className="block">
      <Label text={formatMessage(expiration)} />
      <input
        onChange={handleExpirationChange}
        id="steps-range"
        type="range"
        min="0"
        max={expirationOptions.length - 1}
        value={expirationOptions.findIndex(o => o.expiration === value)}
        step="1"
        className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer accent-secondary-400"
        disabled={disabled}
      />
      {expirationOption.label}
    </label>
  );
}
