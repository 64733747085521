import { FormattedMessage } from 'react-intl';

import Button from '@components/ui/Button';
import Icon from '@components/ui/Icon';

type Props = {
  reset: () => void;
  type: 'Upload' | 'Transfer' | 'CheckoutSuccess' | 'CheckoutCancelled' | 'TokenTransfer';
};

const SuccessScreen = ({ reset, type }: Props) => {
  const getText = () => {
    if (type === 'Upload') {
      return (
        <FormattedMessage
          id="success.uploadComplete"
          defaultMessage="Upload complete!"
          description="Upload complete text"
        />
      );
    }

    if (type === 'Transfer') {
      return (
        <FormattedMessage
          id="success.transferInitiated"
          defaultMessage="Transfer initiated!"
          description="Transfer initiated text"
        />
      );
    }

    if (type === 'CheckoutSuccess') {
      return (
        <FormattedMessage
          id="success.checkoutSuccessful"
          defaultMessage="Order complete!"
          description="Order complete text"
        />
      );
    }

    if (type === 'CheckoutCancelled') {
      return (
        <FormattedMessage
          id="success.checkoutCancelled"
          defaultMessage="Order cancelled!"
          description="Order complete text"
        />
      );
    }

    if (type === 'TokenTransfer') {
      return (
        <FormattedMessage id="success.tokenTransferSuccessful" defaultMessage="Tokens successfully transferred!" />
      );
    }

    return 'Great success!';
  };

  const getButtonText = () => {
    if (type === 'Upload') {
      return (
        <FormattedMessage
          id="success.anotherUpload"
          defaultMessage="Upload another file"
          description="Another upload button text"
        />
      );
    }

    if (type === 'Transfer' || type === 'TokenTransfer') {
      return (
        <FormattedMessage
          id="success.anotherTransfer"
          defaultMessage="Make another transfer"
          description="Another transfer button text"
        />
      );
    }

    if (type === 'CheckoutSuccess' || type === 'CheckoutCancelled') {
      return (
        <FormattedMessage
          id="success.returnToDashboard"
          defaultMessage="Return to dashboard"
          description="Return to dashboard text"
        />
      );
    }

    return 'Reset';
  };

  return (
    <div className="flex justify-center h-full items-center">
      <div className="text-center">
        <h3 className="mb-2">
          {getText()}
          <span className="text-green-400 text-2xl ml-2">
            <Icon type="faCheck" />
          </span>
        </h3>

        {type !== 'CheckoutCancelled' && (
          <p className=" text-gray-400">
            <FormattedMessage
              id="success.confirmationTime"
              defaultMessage="It may take about 10 seconds for the action to be fully processed"
              description="Confirmation time message"
            />
          </p>
        )}

        <div className="flex gap-4 justify-center mt-6">
          <Button onClick={reset} color="primary-bordered">
            {getButtonText()}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SuccessScreen;
