import { cryptography } from '@liskhq/lisk-client/browser';
import { QueryClient } from '@tanstack/react-query';
import { cloneDeep } from 'lodash';

import { CustomField } from '@components/upload/CustomFields';
import { getCollectionsByIds, getFilesByIds } from '@services/api';
import {
  AccountProps,
  Collection,
  DamFile,
  ExtendExpirationCommandProps,
  Network,
  NotificationItem,
  NotificationType,
  UpdateAccountDetailsCommandProps,
  UpdateCollectionCommandProps,
  UpdateFileCommandProps,
} from '@/types';

import { createDateTime, jsonToBuffer } from './helpers';
import { createDummyCollection } from './mocks';

export const optimisticallyAddCollection = (
  queryClient: QueryClient,
  queryKey: string[],
  collectionId: string,
  lsk32address: string,
  collectionData: { title: string; transferFee: number; accessPermissionFee: number; fileIds: string[] },
) => {
  const address = cryptography.address.getAddressFromLisk32Address(lsk32address);

  queryClient.setQueryData<Awaited<ReturnType<typeof getCollectionsByIds>>>(queryKey, prevData => {
    const prev = prevData || {
      collections: [],
      total: 0,
    };

    const newCollection = createDummyCollection(collectionId, address, collectionData);

    return {
      collections: [...prev.collections, newCollection],
      total: prev.total + 1,
    };
  });

  queryClient.setQueryData<AccountProps>(['account'], prevData => {
    if (!prevData) {
      return prevData;
    }

    const updatedData = cloneDeep(prevData);
    updatedData.collectionsOwned.push(collectionId);

    return updatedData;
  });
};

export const optimisticallyUpdateCollection = (
  queryClient: QueryClient,
  queryKey: string[],
  params: UpdateCollectionCommandProps,
) => {
  queryClient.setQueryData<Awaited<ReturnType<typeof getCollectionsByIds>>>(queryKey, prevData => {
    if (!prevData) {
      return prevData;
    }

    const updatedData = cloneDeep(prevData);

    const collection = updatedData.collections.find(c => c.id === params.collectionId);

    if (collection) {
      collection.fileIds = params.fileIds;
      collection.transferFee = params.transferFee;
    }

    return updatedData;
  });
};

export const optimisticallyRemoveCollection = (queryClient: QueryClient, queryKey: string[], collectionId: string) => {
  queryClient.setQueryData<Awaited<ReturnType<typeof getCollectionsByIds>>>(queryKey, prevData => {
    const prev = prevData || {
      collections: [],
      total: 0,
    };

    return {
      collections: prev.collections.filter(col => col.id !== collectionId),
      total: prev.total + 1,
    };
  });

  queryClient.setQueryData<AccountProps>(['account'], prevData => {
    if (!prevData) {
      return prevData;
    }

    const updatedData = cloneDeep(prevData);
    updatedData.collectionsOwned.push(collectionId);

    return updatedData;
  });
};

export const optimisticallyUpdateFileCollection = (
  queryClient: QueryClient,
  queryKey: string[],
  fileIds: string[],
  collection: Collection,
) => {
  queryClient.setQueryData<Awaited<ReturnType<typeof getFilesByIds>>>(queryKey, prevData => {
    if (!prevData) {
      return prevData;
    }

    const updatedData = cloneDeep(prevData);

    /** Add collection id to files */
    fileIds
      .filter(fileId => !collection.fileIds.includes(fileId)) // filter out files already in collection
      .forEach(id => {
        const file = updatedData.files.find(f => f.data.id === id);

        if (file) {
          file.meta.collection.id = collection.id;
          file.meta.collection.title = collection.title;
        }
      });

    /** Remove collectionId from files not part of collection any more  */
    collection.fileIds
      .filter(id => !fileIds.includes(id))
      .forEach(id => {
        const file = updatedData.files.find(f => f.data.id === id);

        if (file) {
          file.meta.collection.id = '';
          file.meta.collection.title = '';
        }
      });

    return updatedData;
  });
};

export const optimisticallyUpdateFile = (
  queryClient: QueryClient,
  params: UpdateFileCommandProps,
  isPrivate: boolean,
  customFields: CustomField[],
) => {
  const { fileId, accessPermissionFee, transferFee } = params;

  queryClient.setQueryData<DamFile>(['view', fileId], oldFile => {
    if (!oldFile) {
      return oldFile;
    }

    return {
      ...oldFile,
      data: {
        ...oldFile.data,
        accessPermissionFee,
        transferFee,
        private: isPrivate,
        customFields: jsonToBuffer(customFields),
      },
    };
  });
};

export const removeRequests = (queryClient: QueryClient, ids: string[]) => {
  queryClient.setQueryData<AccountProps>(['account'], oldAccount => {
    if (!oldAccount) {
      return oldAccount;
    }

    const updatedAccount = { ...oldAccount };

    updatedAccount.incomingFileRequests = updatedAccount.incomingFileRequests.filter(r => !ids.includes(r.requestId));
    updatedAccount.outgoingFileRequests = updatedAccount.outgoingFileRequests.filter(r => !ids.includes(r.requestId));
    updatedAccount.incomingCollectionRequests = updatedAccount.incomingCollectionRequests.filter(
      r => !ids.includes(r.requestId),
    );
    updatedAccount.outgoingCollectionRequests = updatedAccount.outgoingCollectionRequests.filter(
      r => !ids.includes(r.requestId),
    );

    return updatedAccount;
  });
};

export const optimisticallyUpdateAccountDetails = (
  queryClient: QueryClient,
  props: UpdateAccountDetailsCommandProps,
) => {
  queryClient.setQueryData<AccountProps>(['account'], oldAccount => {
    if (!oldAccount) {
      return oldAccount;
    }

    const { type, companyDetails, personalDetails } = props;

    return { ...oldAccount, type, companyDetails, personalDetails };
  });
};

export const removeAccountCache = (queryClient: QueryClient) => {
  queryClient.removeQueries({ queryKey: ['account'], exact: true });
};

export const optimisticallyUpdateExpirations = (queryClient: QueryClient, params: ExtendExpirationCommandProps) => {
  const { fileIds, amount } = params;

  for (const fileId of fileIds) {
    queryClient.setQueryData<DamFile>(['view', fileId], oldFile => {
      if (!oldFile) {
        return oldFile;
      }

      const updatedFile = { ...oldFile };
      updatedFile.meta.expiration = createDateTime(oldFile.meta.expiration.unix + amount);
      updatedFile.meta.lastModified = createDateTime(params.timestamp);

      return updatedFile;
    });
  }
};

export const optimisticallyRemoveNotification = (
  queryClient: QueryClient,
  address: string,
  fileId: string,
  type: NotificationType,
) => {
  queryClient.setQueryData<NotificationItem[]>(['notifications', address], oldNotifications => {
    if (!oldNotifications) {
      return oldNotifications;
    }

    let updatedNotifications: NotificationItem[];

    if (type === 'removed') {
      updatedNotifications = oldNotifications.filter(n => !(n.fileId === fileId));
    } else {
      updatedNotifications = oldNotifications.filter(n => !(n.fileId === fileId && n.type === type));
    }

    return updatedNotifications;
  });
};

export const optimisticallyRemoveAllNotifications = (queryClient: QueryClient, address: string) => {
  queryClient.setQueryData<NotificationItem[]>(['notifications', address], oldNotifications => {
    if (!oldNotifications) {
      return oldNotifications;
    }

    return [];
  });
};

export const optimisticallyUpdateAllowedNetworks = (
  queryClient: QueryClient,
  updatedNetwork: Network,
  lsk32address?: string,
) => {
  queryClient.setQueryData<Network[]>(['account', 'allowedNetworks', lsk32address], oldNetworks => {
    if (!oldNetworks) {
      return [updatedNetwork];
    }

    const updatedData = [...oldNetworks];
    const network = updatedData.find(n => n.id === updatedNetwork.id);

    if (!network) {
      updatedData.push(updatedNetwork);
    } else {
      network.isPublic = updatedNetwork.isPublic;
      network.accountsAllowed = updatedNetwork.accountsAllowed;
      network.owner = updatedNetwork.owner;
    }

    return updatedData;
  });
};
