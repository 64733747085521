import { useState } from 'react';

export default function useForm<T>(formInit: T) {
  const [form, setForm] = useState(structuredClone(formInit));

  const updateForm = (field: keyof typeof formInit, value: any) => {
    if (typeof value !== typeof form[field]) {
      return;
    }

    setForm(prevState => ({ ...prevState, [field]: value }));
  };

  const resetForm = () => {
    setForm(structuredClone(formInit));
  };

  return { form, updateForm, resetForm };
}
