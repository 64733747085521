import { useQuery } from '@tanstack/react-query';
import { getAllowedNetworksForAddress } from '@services/api';
import { handleError } from '@utils/errors';

export default function useAllowedNetworks(lsk32address?: string) {
  const { data, isLoading } = useQuery({
    queryKey: ['account', 'allowedNetworks', lsk32address],
    queryFn: () => getAllowedNetworksForAddress(lsk32address ?? ''),
    onError: handleError,
  });

  return {
    data: data ?? [],
    isLoading,
  };
}
