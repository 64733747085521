import { FormattedMessage } from 'react-intl';

import { Wallet } from '@/types';
import Button from '@components/ui/Button';
import Icon from '@components/ui/Icon';
import useAccountData from '@hooks/useAccountData';
import { FORM_INIT } from '.';

type Props = {
  isAuthenticated: boolean;
  isTimedTransfer: boolean;
  wallet: Wallet | null;
  form: typeof FORM_INIT;
  uploadCost: number | string;
};

const UploadButton = ({ isAuthenticated, wallet, isTimedTransfer, form, uploadCost }: Props) => {
  const { account } = useAccountData();

  const getText = () => {
    if (wallet && isAuthenticated && account) {
      if (isTimedTransfer) {
        return (
          <FormattedMessage
            id="upload.timedTransferFooterRev1"
            defaultMessage="Sending this timed transfer costs {price} token(s)"
            values={{ price: uploadCost }}
            description="Timed transfer footer"
          />
        );
      }

      if (!isTimedTransfer) {
        return (
          <FormattedMessage
            id="upload.uploadFooterRev1"
            defaultMessage="Uploading this file costs {price} token(s)"
            values={{ price: uploadCost }}
            description="Upload footer"
          />
        );
      }
    }

    if (!isAuthenticated) {
      return (
        <FormattedMessage
          id="upload.pleaseLogin"
          defaultMessage="Please log in to upload a file"
          description="Please login footer"
        />
      );
    }

    if (!account) {
      return (
        <FormattedMessage
          id="upload.pleaseInitialize"
          defaultMessage="Please initialize your account before uploading"
          description="Please initialize footer"
        />
      );
    }

    if (isAuthenticated) {
      return (
        <FormattedMessage
          id="upload.pleaseCreateWallet"
          defaultMessage="Please create or import a wallet to upload a file"
          description="Please create wallet footer"
        />
      );
    }

    return 'Error';
  };

  return (
    <div className="text-center">
      <Button size="lg" type="submit" disabled={!isAuthenticated || !wallet || !account}>
        <FormattedMessage id="upload.uploadFile" defaultMessage="Upload File" description="Upload File button text" />
      </Button>

      <p className="text-sm mt-4 text-gray-400">
        <Icon type="faLock" /> <span>{getText()}</span>
      </p>
    </div>
  );
};

export default UploadButton;
