import { FormattedMessage } from 'react-intl';

type Props = { fileIsSelected: boolean; cost: number; discount: number };

export default function CostIndicator({ cost, discount, fileIsSelected }: Props) {
  return (
    <div className="text-center">
      <div className="-mt-8">
        <span className="text-sm">
          <FormattedMessage id="expiration.cost" defaultMessage="Cost" />:
        </span>
        <br />

        <span className="text-2xl font-bold">
          {fileIsSelected ? (
            <FormattedMessage
              id="expiration.numOfTokens"
              defaultMessage="{tokens} tokens"
              values={{
                tokens: cost,
              }}
            />
          ) : (
            'N/A'
          )}
        </span>

        <br />
        <div className="text-xs text-gray-400 h-6 -mb-6">
          {discount > 0 && (
            <span>
              <FormattedMessage
                id="expiration.discount"
                defaultMessage="Includes {discount} % discount"
                values={{ discount }}
              />
            </span>
          )}
        </div>
      </div>
    </div>
  );
}
