import { FormattedMessage, useIntl } from 'react-intl';

import ManageNetworksModal from '@components/shared/ManageNetworksModal';
import Label from '@components/ui/Label';
import LabelDescription from '@components/ui/LabelDescription';
import useAllowedNetworks from '@hooks/useAllowedNetworks';

type Props = {
  lsk32address: string;
  onChangeHandler: (value: string) => void;
  value: string;
};

export default function NetworkSelect({ lsk32address, onChangeHandler, value }: Props) {
  const { data } = useAllowedNetworks(lsk32address);
  const { formatMessage } = useIntl();

  return (
    <label className="block space-y-1">
      <Label text={`${formatMessage({ id: 'upload.network.label', defaultMessage: 'Storage Network' })}`} />

      <select className="base-input" value={value} onChange={e => onChangeHandler(e.target.value)}>
        <option value="primary">
          <FormattedMessage id="upload.network.primary" defaultMessage="Primary" />
        </option>
        {data.map(c => (
          <option value={c.label} key={c.id}>
            {c.label}
          </option>
        ))}
      </select>

      <LabelDescription>
        <FormattedMessage id="upload.network.description" defaultMessage="Choose where your file is stored" />.{' '}
        <ManageNetworksModal />
      </LabelDescription>
    </label>
  );
}
