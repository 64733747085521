import { DamFile } from '@/types';
import DetailInline from '@components/ui/DetailInline';
import Icon from '@components/ui/Icon';
import { displayDateFromSeconds } from '@utils/formatting';

type Props = {
  file: DamFile;
  expirationExtension: number;
};

const ExpirationIndicator = ({ file, expirationExtension }: Props) => {
  const oldDate = displayDateFromSeconds(file.meta.expiration.unix);
  const newDate = displayDateFromSeconds(file.meta.expiration.unix + expirationExtension);

  return (
    <DetailInline
      label={file.data.title}
      value={
        <span>
          {oldDate} <Icon type="faArrowRight" className="mx-1" /> {newDate}
        </span>
      }
    />
  );
};

export default ExpirationIndicator;
