import { useIntl } from 'react-intl';

import SEO from '@components/ui/SEO';
import { tos } from '@intl/generic';

const TosPage = () => {
  const { formatMessage } = useIntl();
  const title = formatMessage(tos);

  return (
    <section className="max-w-xl w-full">
      <SEO pageTitle={title} />
      Our Terms and Conditions are currently being developed and will be available soon. We appreciate your
      understanding and encourage you to check back for updates.
    </section>
  );
};

export default TosPage;
