import { cryptography } from '@liskhq/lisk-client/browser';

import config from '@/config';
import {
  CancelRequestCommandProps,
  CreateCollectionCommandProps,
  CreateNetworkCommandProps,
  RequestCollectionAccessCommandProps,
  RequestCollectionOwnershipCommandProps,
  RequestCollectionTransferCommandProps,
  RequestFileAccessCommandProps,
  RequestFileOwnershipCommandProps,
  RequestFileTransferCommandProps,
  TransferTokenCommandProps,
  UpdateAccountDetailsCommandProps,
  UpdateCollectionCommandProps,
  UpdateFileCommandProps,
  UpdateNetworkCommandProps,
} from '@/types';
import { bufferToHex } from '@utils/crypto';
import * as api from './api';

const getPrivateKey = async (passphrase: string) => {
  const privateKey = await cryptography.ed.getPrivateKeyFromPhraseAndPath(passphrase, config.derivationPath);
  return bufferToHex(privateKey);
};

export const sendRequestFileOwnershipCommand = async (passphrase: string, params: RequestFileOwnershipCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'requestFileOwnership',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendRequestFileAccessCommand = async (passphrase: string, params: RequestFileAccessCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'requestFileAccess',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendRequestFileTransferCommand = async (passphrase: string, params: RequestFileTransferCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'requestFileTransfer',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendUpdateFileCommand = async (passphrase: string, params: UpdateFileCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'updateFile',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendCreateCollectionCommand = async (passphrase: string, params: CreateCollectionCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'createCollection',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendUpdateCollectionCommand = async (passphrase: string, params: UpdateCollectionCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'updateCollection',
      fee: BigInt(10000000),
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendRequestCollectionOwnershipCommand = async (
  passphrase: string,
  params: RequestCollectionOwnershipCommandProps,
) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'requestCollectionOwnership',
      fee: BigInt(10000000),
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendRequestCollectionAccessCommand = async (
  passphrase: string,
  params: RequestCollectionAccessCommandProps,
) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'requestCollectionAccess',
      fee: BigInt(10000000),
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendRequestCollectionTransferCommand = async (
  passphrase: string,
  params: RequestCollectionTransferCommandProps,
) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'requestCollectionTransfer',
      fee: BigInt(10000000),
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendCancelRequestCommand = async (passphrase: string, params: CancelRequestCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'cancelRequest',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendUpdateAccountDetailsCommand = async (passphrase: string, params: UpdateAccountDetailsCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'updateAccountDetails',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendCreateNetworkCommand = async (passphrase: string, params: CreateNetworkCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'createNetwork',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendUpdateNetworkCommand = async (passphrase: string, params: UpdateNetworkCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'storage',
      command: 'updateNetwork',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};

export const sendTransferTokenCommand = async (passphrase: string, params: TransferTokenCommandProps) => {
  const client = await api.getClient();

  const tx = await client.transaction.create(
    {
      module: 'token',
      command: 'transfer',
      fee: config.baseFee,
      params,
    },
    await getPrivateKey(passphrase),
  );

  return client.transaction.send(tx);
};
