import { faGithub, faInstagram, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import {
  faBell,
  faCopy,
  faEnvelope as faEnvelopOutline,
  faFile as faFileOutline,
  faFolder,
  faFolder as faFolderOutline,
  faMoneyBillAlt,
} from '@fortawesome/free-regular-svg-icons';

import {
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faBook,
  faBug,
  faCartShopping,
  faCheck,
  faCircle,
  faCircleInfo,
  faCircleNotch,
  faClock,
  faCloudArrowUp,
  faCoins,
  faCrown,
  faDog,
  faDownload,
  faEdit,
  faEnvelope,
  faExchangeAlt,
  faFile,
  faFont,
  faGlobe,
  faHandPointer,
  faImage,
  faList,
  faLock,
  faPlus,
  faRightLeft,
  faShare,
  faSpinner,
  faStar,
  faTrash,
  faUser,
  faUserGroup,
  faUserTie,
  faVideo,
  faVolumeHigh,
  faArrowDown,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Props = {
  type: keyof typeof icons;
  className?: string;
};

const icons = {
  faArrowDown,
  faMoneyBillAlt,
  faCoins,
  faHandPointer,
  faCrown,
  faEnvelope,
  faInstagram,
  faLinkedin,
  faTwitter,
  faGithub,
  faBook,
  faArrowLeft,
  faCircle,
  faArrowUp,
  faCircleNotch,
  faCircleInfo,
  faDog,
  faSpinner,
  faBug,
  faCloudArrowUp,
  faLock,
  faCopy,
  faTrash,
  faFolder,
  faCheck,
  faImage,
  faVideo,
  faFont,
  faFile,
  faVolumeHigh,
  faDownload,
  faEdit,
  faUserGroup,
  faUser,
  faUserTie,
  faStar,
  faList,
  faClock,
  faRightLeft,
  faExchangeAlt,
  faCartShopping,
  faGlobe,
  faShare,
  faArrowRight,
  faBell,
  faEnvelopOutline,
  faPlus,
  faFileOutline,
  faFolderOutline,
};

const Icon = ({ type, className }: Props) => {
  return <FontAwesomeIcon icon={icons[type]} className={className} />;
};

export default Icon;
