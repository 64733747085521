import { ReactElement } from 'react';

const DetailInline = ({ label, value }: { label: string | ReactElement; value: any }) => (
  <div className="flex justify-between gap-8">
    <div>{label}</div>
    <div className="text-right font-bold text-nowrap overflow-hidden overflow-ellipsis">{value}</div>
  </div>
);

export default DetailInline;
