import fileDownload from 'js-file-download';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { DamFile } from '@/types';
import Button from '@components/ui/Button';
import LabelDescription from '@components/ui/LabelDescription';
import Spinner from '@components/ui/Spinner';
import useWallet from '@hooks/useWallet';
import { download, downloading, freelyAccessibleText, processing, transfer } from '@intl/generic';
import { handleDamDownload, handleLoadingProgress } from '@services/axios';
import { handleError } from '@utils/errors';
import { fileIsPArtOfCollection } from '@utils/helpers';

type Props = {
  file: DamFile;
  isOwner: boolean;
  isAllowed: boolean;
  isTransferrable: boolean;
  isExpired: boolean;
  freelyAccessible: boolean;
};

const FileActions = ({ file, isOwner, isAllowed, isTransferrable, isExpired, freelyAccessible }: Props) => {
  const { wallet } = useWallet();
  const [isDownloading, setIsDownloading] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const isPartOfCollection = fileIsPArtOfCollection(file);
  const { formatMessage } = useIntl();

  const handleFileDownload = async () => {
    if (!file) {
      return;
    }

    if (!freelyAccessible && !wallet?.passphrase) {
      return;
    }

    setLoadingProgress(0);
    setIsDownloading(true);

    try {
      const download = await handleDamDownload(
        { passphrase: wallet?.passphrase ?? '', fileId: file.data.id },
        { onUploadProgress: e => handleLoadingProgress(e, setLoadingProgress) },
      );

      const blob = new Blob([download.data], { type: file.data.type });
      fileDownload(blob, file.data.name);
      setIsDownloading(false);
    } catch (err) {
      setIsDownloading(false);
      handleError(err);
    }
  };

  if (isDownloading) {
    return (
      <div className="text-center h-7">
        <span className="text-secondary-500 mr-2">
          <Spinner />
        </span>
        <span className="font-bold">{loadingProgress} %</span>
        <span className="text-xs text-gray-400 block">
          {loadingProgress === 0 ? `${formatMessage(processing)}...` : `${formatMessage(downloading)}...`}
        </span>
      </div>
    );
  }

  return (
    <div className="flex justify-center gap-4">
      {isTransferrable && !isPartOfCollection && !isExpired && (
        <Link to={`/transfer/file?defaultValue=${file.data.id}`} className="text-black">
          <Button color="primary-bordered">
            <FormattedMessage {...transfer} />
          </Button>
        </Link>
      )}

      <div className="text-center space-y-1">
        <Button onClick={handleFileDownload} disabled={!(isAllowed || isOwner) || isExpired}>
          <FormattedMessage {...download} />
        </Button>

        {freelyAccessible && !isOwner && (
          <LabelDescription>
            <FormattedMessage {...freelyAccessibleText} />
          </LabelDescription>
        )}

        {!isOwner && !isAllowed && file.meta.collection && (
          <LabelDescription>
            <FormattedMessage id="view.openCollectionModal" defaultMessage="Open collection modal to request access" />
          </LabelDescription>
        )}

        {isExpired && (
          <LabelDescription>
            <FormattedMessage id="view.fileExpired" defaultMessage="File is expired" />
          </LabelDescription>
        )}
      </div>
    </div>
  );
};

export default FileActions;
