import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import AccountTypeText from '@components/shared/AccountTypeText';
import Button from '@components/ui/Button';
import DetailInline from '@components/ui/DetailInline';
import Hr from '@components/ui/Hr';
import Modal from '@components/ui/Modal';
import Spinner from '@components/ui/Spinner';
import {
  accountDetails,
  accountType,
  close,
  collections,
  companyName,
  files,
  name,
  notFound,
  surname,
  username,
  vatNumber,
} from '@intl/generic';
import { fetchAggregatedAccount } from '@services/api';
import { getLisk32AddressFromAddress } from '@utils/crypto';
import { handleError } from '@utils/errors';
import { devLog } from '@utils/helpers';

type Props = { address: Buffer | string; format: 'button' | 'text' };

export default function AccountDetailsModal({ address, format }: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const ownerAddress = typeof address === 'string' ? address : getLisk32AddressFromAddress(address);

  const { data, isLoading } = useQuery({
    queryKey: ['account', ownerAddress],
    queryFn: () => fetchAggregatedAccount(ownerAddress),
    onSuccess: data => devLog(data),
    onError: handleError,
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (!data) {
    return (
      <span>
        <FormattedMessage {...notFound} />
      </span>
    );
  }

  if (format === 'text') {
    return <div className="px-1 py-1">{isLoading ? <Spinner /> : data?.username ?? address}</div>;
  }

  return (
    <>
      <Button link onClick={() => setModalIsOpen(true)}>
        {data.username}{' '}
      </Button>

      <Modal title={formatMessage(accountDetails)} isOpen={modalIsOpen} handleClose={() => setModalIsOpen(false)}>
        <div className="space-y-4">
          <div className="flex justify-center">
            <div className="max-w-md w-full">
              <DetailInline label={formatMessage(username)} value={data.username} />
              <DetailInline label={formatMessage(files)} value={data.filesOwned.length} />
              <DetailInline label={formatMessage(collections)} value={data.collectionsOwned.length} />
              <div className="my-4" />
              <DetailInline label={formatMessage(accountType)} value={<AccountTypeText type={data.type} />} />
              {data.type === 'company' && (
                <>
                  <DetailInline label={formatMessage(companyName)} value={data.companyDetails.name} />
                  <DetailInline label={formatMessage(vatNumber)} value={data.companyDetails.vat} />
                </>
              )}
              {data.type === 'personal' && (
                <>
                  <DetailInline label={formatMessage(name)} value={data.personalDetails.name} />
                  <DetailInline label={formatMessage(surname)} value={data.personalDetails.surname} />
                </>
              )}
            </div>
          </div>

          <Hr />
          <div className="text-center">
            <Button onClick={() => setModalIsOpen(false)}>
              <FormattedMessage {...close} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}
