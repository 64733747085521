import { defineMessage } from 'react-intl';

export const example = defineMessage({
  id: 'generic.example',
  defaultMessage: 'Example',
  description: 'An example variable for chatGPT',
});

export const email = defineMessage({
  id: 'generic.email',
  defaultMessage: 'Email',
});

export const accountDetails = defineMessage({
  id: 'generic.accountDetails',
  defaultMessage: 'Account Details',
});

export const submit = defineMessage({
  id: 'generic.submit',
  defaultMessage: 'Submit',
  description: 'Generic submit text',
});

export const close = defineMessage({
  id: 'generic.close',
  defaultMessage: 'Close',
  description: 'Generic close text',
});

export const update = defineMessage({
  id: 'generic.update',
  defaultMessage: 'Update',
  description: 'Generic update text',
});

export const vatNumber = defineMessage({
  id: 'generic.vatNumber',
  defaultMessage: 'VAT number',
  description: 'Generic VAT number text',
});

export const request = defineMessage({
  id: 'generic.request',
  defaultMessage: 'Request',
  description: 'Generic request text',
});

export const register = defineMessage({
  id: 'generic.register',
  defaultMessage: 'Register',
  description: 'Generic register text',
});

export const cancel = defineMessage({
  id: 'generic.cancel',
  defaultMessage: 'Cancel',
  description: 'Generic cancel text',
});

export const edit = defineMessage({
  id: 'generic.edit',
  defaultMessage: 'Edit',
  description: 'Generic edit text',
});

export const save = defineMessage({
  id: 'generic.save',
  defaultMessage: 'Save',
  description: 'Generic save text',
});

export const importText = defineMessage({
  id: 'generic.importText',
  defaultMessage: 'Import',
  description: 'Generic importText text',
});

export const reset = defineMessage({
  id: 'generic.reset',
  defaultMessage: 'Reset',
  description: 'Generic reset text',
});

export const confirm = defineMessage({
  id: 'generic.confirm',
  defaultMessage: 'Confirm',
  description: 'Generic confirm text',
});

export const attention = defineMessage({
  id: 'generic.attention',
  defaultMessage: 'Attention!',
  description: 'Generic attention text',
});

export const token = defineMessage({
  id: 'generic.token',
  defaultMessage: 'Token',
  description: 'Generic token text',
});

export const tokens = defineMessage({
  id: 'generic.tokens',
  defaultMessage: 'Tokens',
  description: 'Generic tokens text',
});

export const file = defineMessage({
  id: 'generic.file',
  defaultMessage: 'File',
  description: 'Generic file text',
});

export const files = defineMessage({
  id: 'generic.files',
  defaultMessage: 'Files',
  description: 'Generic files text',
});

export const identifier = defineMessage({
  id: 'generic.identifier',
  defaultMessage: 'Identifier',
  description: 'Generic identifier text',
});

export const collection = defineMessage({
  id: 'generic.collection',
  defaultMessage: 'Collection',
  description: 'Generic collection text',
});

export const collections = defineMessage({
  id: 'generic.collections',
  defaultMessage: 'Collections',
  description: 'Generic collections text',
});

export const optional = defineMessage({
  id: 'generic.optional',
  defaultMessage: 'Optional',
  description: 'Generic optional text',
});

export const download = defineMessage({
  id: 'generic.download',
  defaultMessage: 'Download',
  description: 'Generic download text',
});

export const upload = defineMessage({
  id: 'generic.upload',
  defaultMessage: 'Upload',
  description: 'Generic upload text',
});

export const validate = defineMessage({
  id: 'generic.validate',
  defaultMessage: 'Validate',
  description: 'Generic validate text',
});

export const ownership = defineMessage({
  id: 'generic.ownership',
  defaultMessage: 'Ownership',
  description: 'Generic ownership text',
});

export const accessPermission = defineMessage({
  id: 'generic.accessPermission',
  defaultMessage: 'Access Permission',
  description: 'Generic access permission text',
});

export const accept = defineMessage({
  id: 'generic.accept',
  defaultMessage: 'Accept',
  description: 'Generic accept text',
});

export const decline = defineMessage({
  id: 'generic.decline',
  defaultMessage: 'Decline',
  description: 'Generic decline text',
});

export const ownershipRequest = defineMessage({
  id: 'generic.ownershipRequest',
  defaultMessage: 'Ownership request',
  description: 'Generic ownership request text',
});

export const transferRequest = defineMessage({
  id: 'generic.transferRequest',
  defaultMessage: 'Transfer request',
  description: 'Generic transfer request text',
});

export const accessPermissionRequest = defineMessage({
  id: 'generic.accessPermissionRequest',
  defaultMessage: 'Access Permission Request',
  description: 'Generic access permission request text',
});

export const timedTransferRequest = defineMessage({
  id: 'generic.timedTransferRequest',
  defaultMessage: 'Timed transfer request',
  description: 'Generic timed transfer request text',
});

export const requestOwnership = defineMessage({
  id: 'generic.requestOwnership',
  defaultMessage: 'Request Ownership',
  description: 'Generic request ownership text',
});

export const requestAccessPermission = defineMessage({
  id: 'generic.requestAccessPermission',
  defaultMessage: 'Request Access Permission',
  description: 'Generic request access permission text',
});

export const transfer = defineMessage({
  id: 'generic.transfer',
  defaultMessage: 'Transfer',
  description: 'Generic transfer text',
});

export const address = defineMessage({
  id: 'generic.address',
  defaultMessage: 'Address',
  description: 'Generic address text',
});

export const passphrase = defineMessage({
  id: 'generic.passphrase',
  defaultMessage: 'Passphrase',
  description: 'Generic passphrase text',
});

export const cryptography = defineMessage({
  id: 'generic.cryptography',
  defaultMessage: 'Cryptography',
  description: 'Generic cryptography text',
});

export const details = defineMessage({
  id: 'generic.details',
  defaultMessage: 'Details',
  description: 'Generic details text',
});

export const checksum = defineMessage({
  id: 'generic.checksum',
  defaultMessage: 'Checksum',
  description: 'Generic checksum text',
});

export const hash = defineMessage({
  id: 'generic.hash',
  defaultMessage: 'Hash',
  description: 'Generic hash text',
});

export const processing = defineMessage({
  id: 'generic.processing',
  defaultMessage: 'Processing',
  description: 'Generic processing text',
});

export const requestId = defineMessage({
  id: 'generic.requestId',
  defaultMessage: 'Request ID',
  description: 'Generic request ID text',
});

export const sentBy = defineMessage({
  id: 'generic.sentBy',
  defaultMessage: 'Sent by',
  description: 'Generic request ID text',
});

export const requestFor = defineMessage({
  id: 'generic.requestFor',
  defaultMessage: '{type} request for',
});

export const filesInCollection = defineMessage({
  id: 'generic.filesInCollection',
  defaultMessage: 'Files in collection',
});

export const requiredFee = defineMessage({
  id: 'generic.requiredFee',
  defaultMessage: 'Required Fee: {tokens} tokens',
});

export const processingDoNotClose = defineMessage({
  id: 'generic.processingDoNotClose',
  defaultMessage: 'Processing... do not close the browser.',
  description: 'Generic processing do not close browser text',
});

export const downloading = defineMessage({
  id: 'generic.downloading',
  defaultMessage: 'Downloading',
  description: 'Generic downloading text',
});

export const accessPermissionFee = defineMessage({
  id: 'generic.accessPermissionFee',
  defaultMessage: 'Access Permission Fee',
  description: 'Generic access permission fee text',
});

export const transferFee = defineMessage({
  id: 'generic.transferFee',
  defaultMessage: 'Transfer Fee',
  description: 'Generic transfer fee text',
});

export const title = defineMessage({
  id: 'generic.title',
  defaultMessage: 'Title',
  description: 'Generic title text',
});

export const back = defineMessage({
  id: 'generic.back',
  defaultMessage: 'Back',
  description: 'Generic back text',
});

export const continueText = defineMessage({
  id: 'generic.continue',
  defaultMessage: 'Continue',
  description: 'Generic continue text',
});

export const noAccountTypeSelected = defineMessage({
  id: 'generic.noAccountTypeSelected',
  defaultMessage: 'Select an account type',
});

export const privateText = defineMessage({
  id: 'generic.private',
  defaultMessage: 'Private',
  description: 'Generic private text',
});

export const freelyAccessibleText = defineMessage({
  id: 'generic.freelyAccessible',
  defaultMessage: 'Freely accessible',
  description: 'Generic freely accessible text',
});

export const accountType = defineMessage({
  id: 'generic.accountType',
  defaultMessage: 'Account Type',
  description: 'Generic account type text',
});

export const tos = defineMessage({
  id: 'generic.tos',
  defaultMessage: 'Terms & Conditions',
});

export const username = defineMessage({
  id: 'generic.username',
  defaultMessage: 'Username',
  description: 'Generic username text',
});

export const finishRegistration = defineMessage({
  id: 'generic.finishRegistration',
  defaultMessage: 'Finish Registration',
  description: 'Generic finish registration text',
});

export const unknown = defineMessage({
  id: 'generic.unknown',
  defaultMessage: 'Unknown',
  description: 'Generic unknown text',
});

export const name = defineMessage({
  id: 'generic.name',
  defaultMessage: 'Name',
  description: 'Generic name text',
});

export const surname = defineMessage({
  id: 'generic.surname',
  defaultMessage: 'Surname',
  description: 'Generic surname text',
});

export const companyName = defineMessage({
  id: 'generic.companyName',
  defaultMessage: 'Company Name',
  description: 'Generic company name text',
});

export const personal = defineMessage({
  id: 'generic.personal',
  defaultMessage: 'Personal',
  description: 'Generic personal text',
});

export const company = defineMessage({
  id: 'generic.company',
  defaultMessage: 'Company',
  description: 'Generic company text',
});

export const fiscalCode = defineMessage({
  id: 'generic.fiscalCode',
  defaultMessage: 'Fiscal Code',
  description: 'Generic fiscal code text',
});

export const partOfCollection = defineMessage({
  id: 'generic.partOfCollection',
  defaultMessage: 'Part of collection',
  description: 'Generic part of collection text',
});

export const type = defineMessage({
  id: 'generic.type',
  defaultMessage: 'Type',
  description: 'Generic type text',
});

export const size = defineMessage({
  id: 'generic.size',
  defaultMessage: 'Size',
  description: 'Generic size text',
});

export const timedTransfer = defineMessage({
  id: 'generic.timedTransfer',
  defaultMessage: 'Timed Transfer',
  description: 'Generic timed transfer text',
});

export const expiration = defineMessage({
  id: 'generic.expiration',
  defaultMessage: 'Expiration',
  description: 'Generic expiration text',
});

export const createdAt = defineMessage({
  id: 'generic.createdAt',
  defaultMessage: 'Created at',
  description: 'Generic created at text',
});

export const lastModified = defineMessage({
  id: 'generic.lastModified',
  defaultMessage: 'Last modified',
  description: 'Generic last modified text',
});

export const next = defineMessage({
  id: 'generic.next',
  defaultMessage: 'Next',
  description: 'Generic next text',
});

export const previous = defineMessage({
  id: 'generic.previous',
  defaultMessage: 'Previous',
  description: 'Generic previous text',
});

export const action = defineMessage({
  id: 'generic.action',
  defaultMessage: 'Action',
  description: 'Generic action text',
});

export const actions = defineMessage({
  id: 'generic.actions',
  defaultMessage: 'Actions',
  description: 'Generic actions text',
});

export const owner = defineMessage({
  id: 'generic.owner',
  defaultMessage: 'Owner',
  description: 'Generic owner text',
});

export const sender = defineMessage({
  id: 'generic.sender',
  defaultMessage: 'Sender',
  description: 'Generic sender text',
});

export const invalidPassphrase = defineMessage({
  id: 'generic.error.invalidPassphrase',
  defaultMessage: 'Invalid passphrase',
  description: 'Invalid passphrase error',
});

export const unauthorized = defineMessage({
  id: 'generic.error.401.status',
  defaultMessage: 'Unauthorized',
  description: '401 Status Code',
});

export const forbidden = defineMessage({
  id: 'generic.error.403.status',
  defaultMessage: 'Forbidden',
  description: '403 Status Code',
});

export const notFound = defineMessage({
  id: 'generic.error.404.status',
  defaultMessage: 'Not Found',
  description: '404 Status Code',
});

export const serviceUnavailable = defineMessage({
  id: 'generic.error.503.status',
  defaultMessage: 'Service Unavailable',
  description: '503 Status Code',
});

export const recipientInsufficientBalance = defineMessage({
  id: 'generic.error.recipientBalance',
  defaultMessage: 'Recipient has insufficient balance',
  description: 'Insufficient balance error description',
});

export const goBackMessage = defineMessage({
  id: 'generic.goBack',
  defaultMessage: 'Go Back',
  description: 'Generic Go Back text',
});

export const errorMessage = defineMessage({
  id: 'generic.error',
  defaultMessage: 'Error',
  description: 'Generic error text',
});

export const noWallet = defineMessage({
  id: 'generic.error.noWallet',
  defaultMessage: 'No wallet found',
});

export const errorFallback = defineMessage({
  id: 'generic.errorFallbackDescription',
  defaultMessage: 'Something went wrong. Please try again later.',
  description: 'Error fallback description',
});

export const welcome = defineMessage({
  id: 'generic.welcome',
  description: 'Generic greeting',
  defaultMessage: 'Welcome 👋',
});

export const recipient = defineMessage({ id: 'generic.recipient', defaultMessage: 'Recipient' });

export const template = defineMessage({
  id: 'generic.template',
  defaultMessage: 'Template',
  description: 'Generic template text',
});

export const customFieldsText = defineMessage({
  id: 'generic.customFields',
  defaultMessage: 'Custom Fields',
  description: 'Generic Custom Fields text',
});

export const requestTransfer = defineMessage({
  id: 'generic.requestTransfer',
  defaultMessage: 'Request Transfer',
  description: 'Request Transfer text',
});

export const insufficientFee = defineMessage({
  id: 'generic.error.insufficientFee',
  defaultMessage: 'Fee must be at least 1',
  description: 'Insufficient fee error message',
});

export const noPassphraseError = defineMessage({
  id: 'generic.error.passphraseError',
  defaultMessage: 'No passphrase defined',
});

export const ownershipRequested = defineMessage({
  id: 'generic.success.ownershipRequest',
  defaultMessage: 'Ownership successfully requested!',
});

export const accessPermissionRequested = defineMessage({
  id: 'generic.success.accessPermissionRequest',
  defaultMessage: 'Access permission successfully requested!',
});

export const fileDoesNotExist = defineMessage({
  id: 'generic.error.fileDoesNotExist',
  defaultMessage: 'File does not exist',
});

export const downloadPermissionRequested = defineMessage({
  id: 'generic.success.downloadPermissionRequest',
  defaultMessage: 'Download permission successfully requested!',
});

export const requestProcessed = defineMessage({
  id: 'requests.success.requestProcessed',
  defaultMessage: 'Request successfully processed',
});

export const requestAccepted = defineMessage({
  id: 'requests.success.requestAccepted',
  defaultMessage: 'Request successfully accepted',
});

export const requestDeclined = defineMessage({
  id: 'requests.success.requestDeclined',
  defaultMessage: 'Request successfully declined',
});

export const requestCancelled = defineMessage({
  id: 'requests.success.requestCancelled',
  defaultMessage: 'Request successfully cancelled',
});

export const availableFiles = defineMessage({
  id: 'collections.availableFiles',
  defaultMessage: 'Available Files',
});

export const freelyAccessibleDescription = defineMessage({
  id: 'upload.freelyAccessibleDescription',
  defaultMessage: 'Downloadable by anyone',
});

export const oneMonth = defineMessage({
  id: 'upload.expiration.oneMonth',
  defaultMessage: '1 Month',
});

export const nMonths = defineMessage({
  id: 'upload.expiration.nMonths',
  defaultMessage: '{months} Months',
});

export const oneYear = defineMessage({
  id: 'upload.expiration.oneYear',
  defaultMessage: '1 Year',
});

export const nYears = defineMessage({
  id: 'upload.expiration.nYear',
  defaultMessage: '{years} Years',
});

export const enterUsername = defineMessage({
  id: 'register.enterUsername',
  defaultMessage: 'Enter a username',
});

export const label = defineMessage({ id: 'generic.label', defaultMessage: 'Label' });

export const validUsername = defineMessage({
  id: 'upload.validUsername',
  defaultMessage: 'Valid username',
  description: 'Username input feedback',
});

export const usernameAlreadyExists = defineMessage({
  id: 'register.error.usernameTaken',
  defaultMessage: 'Username already exists',
});
