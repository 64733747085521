import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import config from '@/config';
import logoFull from '@assets/img/logo-full.png';
import Icon from '@components/ui/Icon';
import { tos } from '@intl/generic';
import { getCurrentYear } from '@utils/helpers';
import { useClientStatusStore } from '@stores/useClientStatusStore';

import LocaleSwitcher from './LocaleSwitcher';

const Footer = () => {
  const { clientIsOnline } = useClientStatusStore();

  return (
    <footer className="flex justify-center items-center bg-primary-50">
      <div className="text-center">
        <div className="text-black mb-8 flex justify-center">
          <img src={logoFull} alt={`${config.projectTitle}`} className="w-36" />
        </div>

        <div className="text-sm font-thin">
          <p className="flex justify-center gap-2">
            <span>
              <FormattedMessage id="footer.appVersion" defaultMessage="App version" /> {process.env.REACT_APP_VERSION}
            </span>{' '}
            | <span>{process.env.REACT_APP_GIT_SHA}</span> |
            <LocaleSwitcher />
          </p>

          <p className="my-3">
            <FormattedMessage id="footer.networkStatus" defaultMessage="Network Status" />:
            <span className="text-xs ml-1">
              <Icon type="faCircle" className={clientIsOnline ? 'text-green-400' : 'text-red-400'} />
            </span>
          </p>

          <div className="text-2xl mb-4 flex justify-center gap-8">
            <a
              href="mailto:hello@verifica.srl"
              target="_blank"
              rel="noreferrer"
              aria-label="E-mail"
              className="text-secondary-400 hover:text-secondary-800"
            >
              <Icon type="faEnvelope" />
            </a>

            <a
              href="https://www.instagram.com/verifica.srl/ "
              target="_blank"
              rel="noreferrer"
              aria-label="Instagram"
              className="text-secondary-400 hover:text-secondary-800"
            >
              <Icon type="faInstagram" />
            </a>

            <a
              href="https://www.linkedin.com/company/verifica-srl/"
              target="_blank"
              rel="noreferrer"
              aria-label="LinkedIn"
              className="text-secondary-400 hover:text-secondary-800"
            >
              <Icon type="faLinkedin" />
            </a>
          </div>

          <p className="mb-16">
            © {getCurrentYear()} {config.projectTitle} -{' '}
            <FormattedMessage id="footer.copyright" defaultMessage="All Rights Reserved" /> -
            <Link to="/terms-and-conditions">
              <FormattedMessage {...tos} />
            </Link>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
