import { useAuth0 } from '@auth0/auth0-react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import config from '@/config';
import requestsLocation from '@assets/img/requests-location.png';
import LogInButton from '@components/layout/LogInButton';
import Button from '@components/ui/Button';
import useAccountData from '@hooks/useAccountData';

const Welcome = () => {
  const { isAuthenticated } = useAuth0();
  const { account } = useAccountData();
  const navigate = useNavigate();

  const accountExists = !!account?.emailHash;

  return (
    <div className="flex justify-center items-center h-full">
      <div className="text-center">
        {isAuthenticated && accountExists && (
          <>
            <h1>
              <FormattedMessage id="welcome.welcomeBack" defaultMessage="Welcome back!" />
            </h1>
            <p className="mt-4 mb-4">
              <FormattedMessage
                id="welcome.alreadySignedUp"
                defaultMessage="You're already signed up, so start uploading! :)"
              />
            </p>
            <Button onClick={() => navigate('/upload')}>
              <FormattedMessage id="welcome.goToUpload" defaultMessage="Go To Upload" />
            </Button>
          </>
        )}

        {(!isAuthenticated || !accountExists) && (
          <>
            <h1>
              <FormattedMessage
                id="welcome.firstWelcome"
                defaultMessage="Welcome to {project}!"
                values={{ project: config.projectTitle }}
              />
            </h1>
            <p className="mt-4 mb-4">
              <FormattedMessage id="welcome.steps" defaultMessage="Follow these steps to access your files" />:
            </p>
            <div className="flex justify-center my-8">
              <div>
                <ul className="list-decimal text-left">
                  <li>
                    <FormattedMessage id="welcome.signUp" defaultMessage="Sign up via the login button" />
                  </li>
                  <li>
                    <FormattedMessage id="welcome.register" defaultMessage="Go through the registration process" />
                  </li>
                  <li>
                    <FormattedMessage
                      id="welcome.acceptRequest"
                      defaultMessage="Accept the transfer on the requests page"
                    />
                  </li>
                </ul>

                <div className="flex justify-center">
                  <img
                    src={requestsLocation}
                    alt="Requests Location"
                    className="shadow-sm border rounded-lg border-gray-200 my-6 h-32"
                  />
                </div>
              </div>
            </div>
            <LogInButton />
          </>
        )}
      </div>
    </div>
  );
};

export default Welcome;
