import { transactions } from '@liskhq/lisk-client/browser';
import getSymbolFromCurrency from 'currency-symbol-map';
import { DateTime } from 'luxon';
import prettyBytes from 'pretty-bytes';

import { getLocale } from '@stores/useCustomizationStore';

export const displayNumber = (input: number | string) => Number(input).toLocaleString();

export const displayBalance = (input?: bigint) => {
  if (!input) {
    return 0;
  }

  const amount = Number(transactions.convertBeddowsToLSK(input.toString()));

  return amount % 1 === 0 ? amount : amount;
};

export const displayFileSize = (input: number) => prettyBytes(input);

export const beddowsToLsk = (amount: BigInt | string) =>
  transactions.convertBeddowsToLSK(typeof amount === 'string' ? amount : amount.toString());

export const lskToBeddows = (amount: number | string) =>
  transactions.convertLSKToBeddows(typeof amount === 'string' ? amount : amount.toString());

export const displayDate = (isoDate: string, locale = getLocale()) =>
  DateTime.fromISO(isoDate).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY, { locale });

export const displayDateFromSeconds = (seconds: number, locale = getLocale()) =>
  DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY, { locale });

export const displayDateTime = (isoDate: string, locale = getLocale()) =>
  DateTime.fromISO(isoDate).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, { locale });

export const displayDateTimeFromSeconds = (seconds: number, locale = getLocale()) =>
  DateTime.fromSeconds(seconds).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY, { locale });

export const formatFiatCurrency = (amount: number, currency: string, locale = getLocale()) => {
  const isInt = amount % 1 === 0;

  const formatter = new Intl.NumberFormat(locale, {
    minimumFractionDigits: isInt ? 0 : 2,
    maximumFractionDigits: isInt ? 0 : 2,
  });

  return ` ${getSymbolFromCurrency(currency)} ${formatter.format(typeof amount !== 'number' ? 0 : amount)}`;
};
