import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { CollectionRequestType, RequestCollectionOwnershipCommandProps } from '@/types';
import Button from '@components/ui/Button';
import Empty from '@components/ui/Empty';
import Hr from '@components/ui/Hr';
import Modal from '@components/ui/Modal';
import Spinner from '@components/ui/Spinner';
import useAccountData from '@hooks/useAccountData';
import useWallet from '@hooks/useWallet';
import {
  accessPermissionRequested,
  cancel,
  close as closeText,
  noPassphraseError,
  ownershipRequested,
  request,
  requestAccessPermission,
  requestOwnership,
  transfer,
} from '@intl/generic';
import { getCollectionById, getFilesByIds } from '@services/api';
import { sendRequestCollectionAccessCommand, sendRequestCollectionOwnershipCommand } from '@services/transactions';
import { handleError } from '@utils/errors';
import { getTransactionTimestamp } from '@utils/helpers';

import CollectionView from './CollectionView';

type Props = {
  collectionMeta: { id: string; title: string };
  setModalIsOpen: Dispatch<SetStateAction<boolean>>;
  modalIsOpen: boolean;
};

const CollectionModal = ({
  collectionMeta,
  setModalIsOpen: setParentModalIsOpen,
  modalIsOpen: parentModalIsOpen,
}: Props) => {
  const [ownershipRequestModalIsOpen, setOwnershipRequestModalIsOpen] = useState(false);
  const [accessRequestModalIsOpen, setAccessRequestModalIsOpen] = useState(false);

  const queryClient = useQueryClient();
  const { account } = useAccountData();
  const { isLoading, data } = useQuery({
    queryKey: ['collection', collectionMeta.id],
    queryFn: () => getCollectionById(collectionMeta.id),
  });
  const { wallet } = useWallet();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const prefetch = async () => {
      if (!data) {
        return;
      }

      await queryClient.prefetchQuery({
        queryKey: ['collection', data.id, 'files', data.fileIds],
        queryFn: () => getFilesByIds(data.fileIds),
      });
    };

    prefetch();
  }, [data, queryClient]);

  const closeHandler = () => {
    setParentModalIsOpen(false);
  };

  const isOwner = !!account?.collectionsOwned.includes(collectionMeta.id);

  const handleRequest = async (type: CollectionRequestType.Ownership | CollectionRequestType.AccessPermission) => {
    let transactionHandler: typeof sendRequestCollectionOwnershipCommand | typeof sendRequestCollectionAccessCommand;
    let modalHandler: typeof setOwnershipRequestModalIsOpen | typeof setAccessRequestModalIsOpen;
    let successMessage: string;

    if (type === CollectionRequestType.Ownership) {
      transactionHandler = sendRequestCollectionOwnershipCommand;
      modalHandler = setOwnershipRequestModalIsOpen;
      successMessage = formatMessage(ownershipRequested);
    } else {
      transactionHandler = sendRequestCollectionAccessCommand;
      modalHandler = setAccessRequestModalIsOpen;
      successMessage = formatMessage(accessPermissionRequested);
    }

    if (!wallet?.passphrase) {
      toast.error(formatMessage(noPassphraseError));
      return;
    }

    const params: RequestCollectionOwnershipCommandProps = {
      id: collectionMeta.id,
      timestamp: getTransactionTimestamp(),
    };

    try {
      await transactionHandler(wallet.passphrase, params);
      modalHandler(false);
      closeHandler();
      toast.success(successMessage);
    } catch (err) {
      handleError(err);
      openParentModal();
    }
  };

  const openParentModal = () => {
    setParentModalIsOpen(true);
    setOwnershipRequestModalIsOpen(false);
    setAccessRequestModalIsOpen(false);
  };

  const openRequestModal = (type: CollectionRequestType.Ownership | CollectionRequestType.AccessPermission) => {
    setParentModalIsOpen(false);

    if (type === CollectionRequestType.Ownership) {
      setOwnershipRequestModalIsOpen(true);
    } else {
      setAccessRequestModalIsOpen(true);
    }
  };

  return (
    <>
      <Modal
        title={formatMessage({
          id: 'collection.link.modalHeader',
          defaultMessage: 'Collection Details',
        })}
        isOpen={parentModalIsOpen}
        handleClose={closeHandler}
      >
        {isLoading && <Spinner />}
        {!isLoading && data && <CollectionView collection={data} setModalIsOpen={setParentModalIsOpen} />}
        {!isLoading && !data && <Empty />}

        <Hr className="my-4" />

        <div className="flex justify-center space-x-4">
          {account && isOwner && (
            <Link to={`/transfer/collection?defaultValue=${collectionMeta.id}`} className="text-black">
              <Button color="primary-bordered">{formatMessage(transfer)}</Button>
            </Link>
          )}

          {account &&
            data?.downloadPermissionLevel === 'standard' &&
            !isOwner &&
            !!!account?.collectionsAllowed.includes(collectionMeta.id) && (
              <Button color="primary-bordered" onClick={() => openRequestModal(CollectionRequestType.AccessPermission)}>
                {formatMessage(requestAccessPermission)}
              </Button>
            )}

          {account && !isOwner && (
            <Button color="primary-bordered" onClick={() => openRequestModal(CollectionRequestType.Ownership)}>
              {formatMessage(requestOwnership)}
            </Button>
          )}

          <Button onClick={closeHandler}>{formatMessage(closeText)}</Button>
        </div>
      </Modal>

      <Modal
        title={formatMessage({
          id: 'collection.link.requestAccessTitle',
          defaultMessage: 'Request download permission of collection',
        })}
        isOpen={accessRequestModalIsOpen}
        handleClose={openParentModal}
      >
        <div className="text-center mb-2">
          <p className="mb-8">
            <FormattedMessage
              id="collection.link.requestAccess"
              defaultMessage="You are about to request download permission of {collection}. Continue?"
              values={{ collection: <span className="font-bold">{collectionMeta.title}</span> }}
            />
          </p>
        </div>

        <div className="flex justify-center gap-8">
          <Button type="button" color="primary-bordered" onClick={openParentModal}>
            <FormattedMessage {...cancel} />
          </Button>

          <Button type="button" onClick={() => handleRequest(CollectionRequestType.AccessPermission)}>
            <FormattedMessage {...request} />
          </Button>
        </div>
      </Modal>

      <Modal
        title={formatMessage({
          id: 'collection.link.requestOwnershipTitle',
          defaultMessage: 'Request ownership of collection',
        })}
        isOpen={ownershipRequestModalIsOpen}
        handleClose={openParentModal}
      >
        <div className="text-center mb-2">
          <p className="mb-8">
            <FormattedMessage
              id="collection.link.requestOwnership"
              defaultMessage="You are about to request the ownership of {collection}. Continue?"
              values={{ collection: <span className="font-bold">{collectionMeta.title}</span> }}
            />
          </p>
        </div>

        <div className="flex justify-center gap-8">
          <Button type="button" color="primary-bordered" onClick={openParentModal}>
            <FormattedMessage {...cancel} />
          </Button>

          <Button type="button" onClick={() => handleRequest(CollectionRequestType.Ownership)}>
            <FormattedMessage {...request} />
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default CollectionModal;
