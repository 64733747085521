import { FormattedMessage, useIntl } from 'react-intl';

import SEO from '@components/ui/SEO';
import { errorFallback, serviceUnavailable } from '@intl/generic';

const ServiceUnavailable = () => {
  const { formatMessage } = useIntl();

  return (
    <div className="flex justify-center items-center h-full">
      <SEO pageTitle="503" pageDescription={`503 - ${formatMessage(serviceUnavailable)}`} />

      <div className="text-center space-y-4">
        <h1>
          503 - <FormattedMessage {...serviceUnavailable} />
        </h1>
        <p className="mb-4">
          <FormattedMessage {...errorFallback} />
        </p>
      </div>
    </div>
  );
};

export default ServiceUnavailable;
