import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import QRCode from 'react-qr-code';

import config from '@/config';
import Button from '@components/ui/Button';
import Clipboard from '@components/ui/Clipboard';
import Hr from '@components/ui/Hr';
import Icon from '@components/ui/Icon';
import Modal from '@components/ui/Modal';
import { close } from '@intl/generic';

type Props = { id: string };

export default function FileSharing({ id }: Props) {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { formatMessage } = useIntl();

  const handleClose = () => setModalIsOpen(false);

  const sharingLink = `${config.projectDomain}/view/${id}`;

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        title={formatMessage({
          id: 'view.share.qrModalTitle',
          defaultMessage: 'Share QR Code',
        })}
        handleClose={handleClose}
      >
        <div style={{ height: 'auto', margin: '0 auto', maxWidth: 480, width: '100%' }}>
          <QRCode
            size={256}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={sharingLink}
            viewBox={`0 0 256 256`}
          />
        </div>

        <Hr className="my-4" />

        <div className="flex justify-center space-x-4">
          <Button type="button" color="primary-bordered" onClick={handleClose}>
            <FormattedMessage {...close} />
          </Button>
        </div>
      </Modal>

      <div className="flex justify-center gap-4 mt-8 text-sm">
        <Button link onClick={() => setModalIsOpen(true)}>
          <Icon type="faShare" className="mr-1" />
          <FormattedMessage id="view.share.shareQr" defaultMessage="Share via QR" />
        </Button>

        <div className="text-secondary-400 hover:underline font-sans">
          <Clipboard
            value={sharingLink}
            className="ml-1 "
            text={formatMessage({
              id: 'view.share.copyLink',
              defaultMessage: 'Copy link',
            })}
          />
        </div>
      </div>
    </>
  );
}
