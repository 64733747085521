import { useAuth0 } from '@auth0/auth0-react';
import { cloneDeep } from 'lodash';
import { useEffect, useState } from 'react';
import { defineMessage, useIntl } from 'react-intl';

import { AccountProps } from '@/types';
import useAccountData from '@hooks/useAccountData';
import { validUsername } from '@intl/generic';
import { fetchAggregatedAccount, fetchMapByEmailHashOrUsername } from '@services/api';
import { hashEmail } from '@utils/crypto';
import { isEmail } from '@utils/helpers';

import Label from './Label';

type Props = {
  setAddressResult: React.Dispatch<
    React.SetStateAction<{ emailHash: string; rawInput: string; account: AccountProps | null; username: string }>
  >;
  disabled?: boolean;
  isTimedTransfer?: boolean;
  hideLabel?: boolean;
};

const DEBOUNCE = 500;

const noOwnAddress = defineMessage({
  id: 'input.error.noOwnAddress',
  defaultMessage: 'Can not use own address',
  description: 'Address error message',
});

const recipientNotFound = defineMessage({
  id: 'input.error.recipientNotFound',
  defaultMessage: 'Recipient not found',
  description: 'Recipient error message',
});

const validEmail = defineMessage({
  id: 'upload.validEmail',
  defaultMessage: 'Valid email',
  description: 'Address input feedback',
});

const validAddress = defineMessage({
  id: 'upload.validAddress',
  defaultMessage: 'Valid address',
  description: 'Address input feedback',
});

export const ADDRESS_RESULT_INIT = {
  emailHash: '',
  username: '',
  rawInput: '',
  account: null as AccountProps | null,
};

const AddressInput = ({ disabled, setAddressResult, isTimedTransfer, hideLabel }: Props) => {
  const [input, setInput] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const { formatMessage } = useIntl();
  const { account: userAccount } = useAccountData();
  const { user } = useAuth0();

  useEffect(() => {
    setError('');
    setSuccess('');

    const validateInputAsEmail = async () => {
      const sanitizedInput = input.toLocaleLowerCase();

      if (user?.email?.toLocaleLowerCase() === sanitizedInput) {
        setError(formatMessage(noOwnAddress));
        setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT) });
        return;
      }

      const emailHash = hashEmail(sanitizedInput);
      const mapByEmail = await fetchMapByEmailHashOrUsername({ emailHash });

      /** unknown e-mails may proceed in case of timed transfers */
      if (!mapByEmail && isTimedTransfer) {
        setSuccess(formatMessage(validEmail));
        setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT), emailHash, rawInput: sanitizedInput });
        return;
      }

      if (!mapByEmail) {
        setError(formatMessage(recipientNotFound));
        setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT) });
        return;
      }

      /** File has been sent here before, but account has not been initialized yet */
      if (mapByEmail && !mapByEmail.lsk32address) {
        setSuccess(formatMessage(validEmail));
        setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT), emailHash, rawInput: sanitizedInput });
        return;
      }

      const account = await fetchAggregatedAccount(mapByEmail.lsk32address);

      if (account) {
        setSuccess(formatMessage(validAddress));
        setAddressResult({ emailHash, account, rawInput: sanitizedInput, username: mapByEmail.username });
        return;
      }

      /** fallback to not found */
      setError(formatMessage(recipientNotFound));
      setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT) });
    };

    const validateInputAsUsername = async () => {
      const sanitizedInput = input.toLocaleLowerCase();

      if (userAccount?.username.toLocaleLowerCase() === sanitizedInput) {
        setError(formatMessage(noOwnAddress));
        setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT) });
        return;
      }

      const mapByUsername = await fetchMapByEmailHashOrUsername({ username: sanitizedInput });

      if (!mapByUsername) {
        setError(formatMessage(recipientNotFound));
        setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT) });
        return;
      }

      const account = await fetchAggregatedAccount(mapByUsername.lsk32address);

      if (account) {
        setAddressResult({
          emailHash: mapByUsername.emailHash,
          account,
          rawInput: sanitizedInput,
          username: mapByUsername.username,
        });
        setSuccess(formatMessage(validUsername));
        return;
      }

      /** fallback to not found */
      setError(formatMessage(recipientNotFound));
      setAddressResult({ ...cloneDeep(ADDRESS_RESULT_INIT) });
    };

    const id = setTimeout(() => {
      if (!input) {
        return;
      }

      if (isEmail(input)) {
        validateInputAsEmail();
      } else {
        validateInputAsUsername();
      }
    }, DEBOUNCE);

    return () => {
      clearTimeout(id);
    };
  }, [input, user?.email, setAddressResult, isTimedTransfer, userAccount?.username, formatMessage]);

  return (
    <label className="block">
      {!hideLabel && (
        <Label
          text={formatMessage({
            id: 'input.emailOrUsernameLabel',
            defaultMessage: 'Recipient E-mail or Username',
            description: 'Input field label',
          })}
        />
      )}

      <input
        placeholder={formatMessage({
          id: 'input.emailOrUsernamePlaceholder',
          defaultMessage: 'Enter an e-mail or username',
          description: 'Input field placeholder',
        })}
        value={input}
        onChange={e => setInput(e.target.value)}
        type="text"
        className="base-input"
        disabled={disabled}
        required
      />

      {error && <span className="text-xs text-red-400">{error}</span>}
      {success && <span className="text-xs text-green-400">{success}</span>}
    </label>
  );
};

export default AddressInput;
